import { BeanType } from "./BeanType";
import { TranslatedValue } from "./TranslatedValue";
import { ProcessingType } from "./ProcessingType";
import { BeanFarm } from "./BeanFarm";
import { TasteProfile } from "./TasteProfile";

/**
 * eg. Brazil Yellow Bourbon
 */

export class BeanStrain {
  constructor(
    readonly id: string,
    public mvccId: string,
    public name: string,
    public type: BeanType,
    public processing: ProcessingType,
    public farm: BeanFarm,
    public tasteProfile: TasteProfile,
    public notes?: TranslatedValue
  ) { }
}

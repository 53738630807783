
import { CenteredLayout } from "../../template/CenteredLayout";
import { BeanMerchantsList, Create } from "./MerchantsList";


export function Merchants() {
  return (
    <CenteredLayout title="Merchants" extra={[<Create key="create" />]}>
      <BeanMerchantsList />
    </CenteredLayout>
  );
}

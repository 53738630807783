import { Slider } from "antd";
import Form from "antd/lib/form";
import { formItemLayout } from "../formLayouts";

// TODO: validation rules!
export const OrdinalInput = (props: { path: string; label: string }) => {
  return (
    <>
      <Form.Item
        labelAlign="left"
        labelCol={formItemLayout.labelCol}
        rules={[
          {
            required: true,
          },
        ]}
        label={`${props.label}`}
        required={true}
        name={[props.path]}
      >
        <Slider
          style={{ marginLeft: 32, marginRight: 32 }}
          step={5}
          marks={{
            0: "Minimum",
            50: "Medium",
            100: "Maximum",
          }}
        />
      </Form.Item>
    </>
  );
};

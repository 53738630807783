import { BeanStrain } from "./BeanStrain";
import { QualityLevel } from "./QualityLevel";

export class BeanOffer {
  constructor(
    readonly id: string,
    public beanStrain: BeanStrain,
    public pricePerKgInEuro: number,
    public expectedQuality: QualityLevel,
    public note: string,
    public harvestDate?: Date,
  ) {}
}

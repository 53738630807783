import { Row, Col, Typography, Card, Avatar } from "antd";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import {
  DownOutlined,
  PauseOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { useMobile } from "./hooks/useMobile";
import { useEffect, useRef, useState } from "react";

const monospaceTitleFontFamily =
  '"Major Mono Display Regular", Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace';
const monospaceSubTitleFontFamily =
  '"Fira Code Regular", Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace';

const backgroundBlack = "rgba(0,0,0,0.8)";
const backgroundWhite = "rgba(255,255,255,1)";
export const Banner = () => {
  const [videoPause, setVideoPause] = useState(false);
  const vidRef = useRef(null);

  const isMobile = useMobile();

  const iconProps = {
    ariaHidden: true,
    ariaLabel: undefined,
    role: undefined,
    style: { color: "black" },
  };

  useEffect(() => {
    if (videoPause) {
      (vidRef.current! as any).pause();
    } else {
      (vidRef.current! as any).play();
    }
  }, [videoPause]);

  return (
    <div>
      <div
        style={{
          overflow: "hidden",
          width: "100%",
          height: "100vh",
          position: "relative",
          objectFit: "cover",
          backgroundImage: 'url("/background.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* https://stackoverflow.com/questions/10797632/simulate-background-sizecover-on-video-or-img */}
        <video
          autoPlay
          muted
          loop
          playsInline
          ref={vidRef}
          id="bannerVideo"
          style={{
            height: "100%",
            width: "177.77777778vh",
            minWidth: "100%",
            minHeight: "56.25vw",
            position: "absolute",
            objectFit: "cover", // this is the key
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <source src="background.mp4" type="video/mp4" />
        </video>
      </div>
      <QueueAnim
        key="QueueAnim"
        type={["bottom", "top"]}
        delay={200}
        style={{
          display: "inline-block",
          position: "absolute",
          top: isMobile ? "0" : "15%",
          margin: "auto",
          left: 0,
          right: 0,
        }}
      >
        <Row justify="center" key="start">
          <Col xs={24} md={20} lg={18} xl={16} xxl={12}>
            <Row gutter={[0, 8]} align="top" justify="center" wrap={true}>
              <Col
                flex="1 1 570px"
                style={{
                  backgroundColor: backgroundBlack,
                  minHeight: isMobile ? 0 : 256,
                }}
              >
                <div style={{ padding: 32 }}>
                  <Row>
                    <Typography.Title
                      style={{
                        display: "inline",
                        color: "white",
                        fontVariant: "caps", // "small-caps",
                        fontFamily: monospaceTitleFontFamily,
                      }}
                    >
                      Beangineers Coffee
                    </Typography.Title>
                  </Row>
                  <Row>
                    <Typography.Paragraph
                      style={{
                        display: "inline",
                        color: "white",
                        fontSize: 20,
                        fontFamily: monospaceSubTitleFontFamily,
                      }}
                    >
                      Notorisch genau vermessen, personalisiert und immer frisch
                      geröstet.
                    </Typography.Paragraph>
                  </Row>
                </div>
              </Col>
              {!isMobile && (
                <Col flex="256px">
                  <Row justify="center">
                    <Col>
                      <Card
                        style={{
                          marginTop: 0,
                          width: 256,
                          height: 256,
                          backgroundColor: backgroundWhite,
                          borderRadius: 0,
                        }}
                      >
                        <img
                          style={{ mixBlendMode: "multiply" }}
                          src="logo.jpeg"
                          width="208px"
                          alt="Beangineers Logo"
                        />
                      </Card>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </QueueAnim>

      <TweenOne
        animation={{
          y: "-=20",
          yoyo: true,
          repeat: -1,
          duration: 1000,
        }}
        style={{
          color: "black",
          bottom: 20,
          position: "absolute",
          left: "50%",
          marginLeft: "-18px",
          textShadow: "2px 2px 8px #FF0000",
          WebkitTextStroke: "20px white",
        }}
        key="icon"
      >
        <button
          aria-label="nach unten scrollen"
          style={{ background: "transparent", border: "none", fontSize: 0 }}
          onClick={() => {
            window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
          }}
        >
          <Avatar
            style={{ backgroundColor: "rgba(255, 255, 255, 0.95)" }}
            size={48}
            icon={<DownOutlined {...iconProps} />}
          />
        </button>
      </TweenOne>

      <button
        aria-label={`video ${videoPause ? "fortsetzen" : "pausieren"}`}
        style={{
          background: "transparent",
          border: "none",
          fontSize: 0,
          color: "black",
          bottom: 20,
          position: "absolute",
          right: 10,
          marginLeft: "-18px",
          textShadow: "2px 2px 8px #FF0000",
          WebkitTextStroke: "20px white",
        }}
        onClick={() => {
          setVideoPause(!videoPause);
        }}
      >
        <Avatar
          style={{ backgroundColor: "rgba(255, 255, 255, 0.95)" }}
          size={48}
          icon={
            videoPause ? (
              <CaretRightOutlined {...iconProps} />
            ) : (
              <PauseOutlined {...iconProps} />
            )
          }
        />
      </button>
    </div>
  );
};

import { TranslatedValue } from "./TranslatedValue";


export class BodyLevel {
  constructor(
    readonly id: string,
    public mvccId: string,
    public name: TranslatedValue,
    public ordinal: number
  ) { }
}

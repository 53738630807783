import { Table, Space, Typography } from "antd";
import { ConfirmationDeleteButton } from "tsx/pages/template/ConfirmationDeleteButton";
import { DefaultForm } from "tsx/pages/template/form/DefaultForm";
import { DefaultFormProps } from "tsx/pages/template/form/defaultFormProps";
import { ObjectSelectInput } from "tsx/pages/template/form/ObjectSelectInput";
import { UpsertModal } from "tsx/pages/template/form/UpsertModal";
import { beanStrainsHooks } from "tsx/pages/beans/beanStrains/BeanStrainsList";
import { PriceInput } from "tsx/pages/template/form/PriceInput";
import { qualityLevelsHooks } from "tsx/pages/beans/qualityLevels/QualityLevelsList";
import { MonthInput } from "tsx/pages/template/form/MonthInput";
import { BeanOffer } from "ts/model/BeanOffer";
import { BeanStrain } from "ts/model/BeanStrain";
import { QualityLevel } from "ts/model/QualityLevel";
import { MultiLanguageOutput } from "tsx/pages/template/list/MultiLanguageOutput";
import { StringInput } from "tsx/pages/template/form/StringInput";
import {
  useCreateBeanOffer,
  useDeleteBeanOffer,
  useUpdateBeanOffer,
} from "./beanMerchantsHooks";

type usedType = BeanOffer;
const initial = new BeanOffer(
  "",
  {} as BeanStrain,
  0.0,
  {} as QualityLevel,
  "",
  undefined
);
const usedTypeName = "Bean Offer";

const DataForm = (props: DefaultFormProps<usedType>) => {
  const qualities = qualityLevelsHooks.useData();
  const strains = beanStrainsHooks.useData();
  // TODO: offers!
  return (
    <DefaultForm {...props}>
      <ObjectSelectInput
        form={props.form!}
        label="Bean Strain"
        loading={strains.isLoading}
        source={strains.data}
        path={["beanStrain"]}
        required
      />
      <PriceInput label="Price/Kg" path="pricePerKgInEuro" />
      <ObjectSelectInput
        form={props.form!}
        label="Expected Quality"
        loading={qualities.isLoading}
        source={qualities.data}
        path={["expectedQuality"]}
        required
      />
      <StringInput path={"notes"} label={"Notes"} required={false} />
      <MonthInput
        label="Harvest Date"
        path={["harvestDate"]}
        form={props.form!}
      />
    </DefaultForm>
  );
};

export const Create = (props: { merchantId: string }) => {
  const action = useCreateBeanOffer(props.merchantId);
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={action}
    />
  );
};

const Update = (props: { data: usedType; merchantId: string }) => {
  const action = useUpdateBeanOffer(props.merchantId);
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const BeanOffersList = (props: {
  offers: BeanOffer[];
  merchantId: string;
}) => {
  const deleteData = useDeleteBeanOffer(props.merchantId);
  return (
    <Table
      title={() => <Typography.Title level={3}>Bean Offers</Typography.Title>}
      footer={() => <Create key="add" merchantId={props.merchantId} />}
      dataSource={props.offers}
      columns={[
        {
          title: "Bean Strain",
          dataIndex: ["beanStrain", "name"],
        },
        {
          title: "Price",
          render: (val: usedType) => `${val.pricePerKgInEuro}€/Kg`,
        },
        {
          title: "Expected Quality",
          render: (val: usedType) => (
            <MultiLanguageOutput val={val.expectedQuality.name} />
          ),
        },
        {
          title: "Harvest Date",
          render: (val: usedType) => {
            if (val.harvestDate) {
              return (
                <>{`${
                  val.harvestDate.getMonth() + 1
                }/${val.harvestDate.getFullYear()}`}</>
              );
            }
            return <>-</>;
          },
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} merchantId={props.merchantId} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

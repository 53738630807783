import { Input } from "antd";
import Form from "antd/lib/form";
import { formItemLayout } from "../formLayouts";

// TODO: validation rules!
export const TranslatedValueInput = (props: {
  path: string[];
  label: string;
  required: boolean;
}) => {
  return (
    <>
      <Form.Item
        labelAlign="left"
        labelCol={formItemLayout.labelCol}
        rules={[
          {
            required: props.required,
            min: 1,
            max: 256,
          },
        ]}
        label={`${props.label} German`}
        required={props.required}
        name={[...props.path, "german"]}
      >
        <Input placeholder={"Deutsch"} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        labelCol={formItemLayout.labelCol}
        rules={[
          {
            required: props.required,
            min: 1,
            max: 256,
          },
        ]}
        label={`${props.label} English`}
        required={props.required}
        name={[...props.path, "english"]}
      >
        <Input placeholder={"English"} />
      </Form.Item>
    </>
  );
};

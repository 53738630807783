import { CenteredLayout } from "../../template/CenteredLayout";
import { BeanBagsList, Create } from "./BeanBagsList";

export function BeanBags() {
  return (
    <CenteredLayout title="Bean Bags" extra={[<Create key="create" />]}>
      <BeanBagsList />
    </CenteredLayout>
  );
}

import { Select } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import { useRef, useState } from "react";
import { v4 } from "uuid";
import { formItemLayout } from "../formLayouts";

export const ObjectMultiSelectInput = <T extends { id: string }>(props: {
  path: string[];
  nameGenerator: (val: T) => string;
  label: string;
  source?: T[];
  loading: boolean;
  form: FormInstance;
  required?: boolean;
}) => {
  const [validationStatus, setValidationStatus] = useState<undefined | "error">(
    undefined
  );
  const searchUid = useRef(`_${v4()}`);
  return (
    <>
      <Form.Item
        noStyle
        name={[...props.path]}
        rules={[
          {
            required: props.required,
            validator: async (rule, value: string, callback) => {
              if (!props.required) return Promise.resolve();
              let subField = props.form.getFieldsValue();
              props.path.forEach((subpath) => {
                subField = subField[subpath];
              });

              const setErrorsInForm = (errors: string[]) => {
                if (errors.length > 0) {
                  setValidationStatus("error");
                } else {
                  setValidationStatus(undefined);
                }
                props.form.setFields([
                  {
                    name: props.path,
                    errors,
                  },
                ]);
              };

              try {
                if (subField.length < 1) {
                  setErrorsInForm(["error"]);
                  return Promise.reject("error");
                }
              } catch (error) {
                setErrorsInForm(["error"]);
                return Promise.reject("error");
              }
              setErrorsInForm([]);
              return Promise.resolve();
            },
          },
        ]}
      >
        <Form.Item name={[searchUid.current]} noStyle>
          <Form.Item
            labelAlign="left"
            labelCol={formItemLayout.labelCol}
            label={props.label}
            required={props.required}
            validateStatus={validationStatus}
            help={validationStatus === "error" ? "selection required" : ""}
            shouldUpdate={(prev, current) => {
              if (!prev || !current) return true;
              let subFieldPrev = prev;
              props.path.forEach((subpath) => {
                if (!subFieldPrev) return true;
                subFieldPrev = subFieldPrev[subpath];
              });
              let subFieldCurrent = current;
              props.path.forEach((subpath) => {
                if (!subFieldCurrent) return true;
                subFieldCurrent = subFieldCurrent[subpath];
              });
              return subFieldPrev !== subFieldCurrent;
            }}
          >
            {() => {
              return (
                <>
                  <Select
                    style={{ width: "100%" }}
                    value={props.form
                      .getFieldValue(props.path)
                      ?.map((p: T) => p.id)}
                    mode="multiple"
                    loading={props.loading}
                    filterOption={(inputValue, option) => {
                      return option?.title
                        ?.toString()
                        .toLowerCase()
                        .includes(inputValue.toLowerCase());
                    }}
                    onChange={(selected: string[]) => {
                      let input = selected;
                      if (!input) {
                        input = [];
                      }
                      if (typeof input === "string") {
                        input = [input];
                      }
                      const data = input
                        .map((s: string) =>
                          props.source?.find((d) => d.id === s)
                        )
                        .filter((u) => u);

                      props.form.setFields([
                        {
                          name: props.path,
                          value: data,
                        },
                      ]);
                      props.form.validateFields([props.path]);
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {props.source?.map((option) => {
                      return (
                        <Select.Option
                          key={option.id}
                          value={option.id}
                          title={props.nameGenerator(option)}
                        >
                          {props.nameGenerator(option)}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </>
              );
            }}
          </Form.Item>
        </Form.Item>
      </Form.Item>
    </>
  );
};

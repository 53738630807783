import { Row, Col, Typography, Avatar } from "antd";
import QueueAnim from "rc-queue-anim";
import Coffee from "@2fd/ant-design-icons/lib/Coffee";
import Star from "@2fd/ant-design-icons/lib/Star";
import HumanGreeting from "@2fd/ant-design-icons/lib/HumanGreeting";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { content3, gutter, landingSectionStyle, wrapper3 } from "./layout";

const values = [
  {
    value: "Frisch",
    description:
      "Kleine Röstchargen von 500g-1000g, individuell auf Bestellung geröstet",
    icon: <Coffee aria-hidden />,
  },
  {
    value: "Personalisiert",
    description:
      "Nur für Dich geröstet mit personalisierten Röstparametern und Kaffeemischungen",
    icon: <HumanGreeting aria-hidden />,
  },
  {
    value: "Höchste Qualität",
    description:
      "Transparente Dokumentation der Herkunft der Rohkaffees und des Röstprozesses, Charge für Charge",
    icon: <Star aria-hidden />,
  },
];

export const Values = () => {
  return (
    <div style={landingSectionStyle}>
      <Row justify="center">
        <Col>
          <Typography.Title level={2}>Unsere Werte</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col {...wrapper3} style={{ marginTop: 50 }}>
          <OverPack playScale={0.25}>
            <QueueAnim
              type="bottom"
              key="block"
              leaveReverse
              component={Row as any}
              componentProps={{ gutter }}
            >
              {values.map((c, i) => {
                return (
                  <Col {...content3} key={`col-${i}`} style={{marginBottom: 32}}>
                    <Row wrap={false}>
                      <Col flex="64px">
                        <Avatar
                          style={{ backgroundColor: "#3d1f14" }}
                          size={52}
                          icon={c.icon}
                        />
                      </Col>
                      <Col flex="auto">
                        <Typography.Title level={3}>{c.value}</Typography.Title>
                        <Typography.Paragraph>
                          {c.description}
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </QueueAnim>
          </OverPack>
        </Col>
      </Row>
    </div>
  );
};

import { Unsubscribe } from "../BeansBackend";
import { BeanMerchantsBackend } from "./BeanMerchantsBackend";
import { GenericLocalStorageBackend } from "../GenericLocalStorageBackend";
import { localStorageBackendBroker } from "../localStorageBackendBroker";
import { BeanOffer } from "ts/model/BeanOffer";
import { Country } from "ts/model/Country";
import { Merchant } from "ts/model/Merchant";
import { v4 } from "uuid";
import { BeanBagOffer } from "ts/model/BeanBagOffer";
import { ShippingBoxOffer } from "ts/model/ShippingBoxOffer";
import { ShippingBox } from "ts/model/ShippingBox";
import { BeanBag } from "ts/model/BeanBag";
import { BeanStrain } from "ts/model/BeanStrain";

type BackendType = Merchant;
const storageKey = "bean-merchants";
const messageSuffix = "BeanMerchant";
export class LocalStorageBeanMerchantsBackend implements BeanMerchantsBackend {
  private readonly backend = new GenericLocalStorageBackend<BackendType>(
    storageKey,
    `add${messageSuffix}`,
    `update${messageSuffix}`,
    `delete${messageSuffix}`,
    localStorageBackendBroker
  );

  constructor() {
    localStorageBackendBroker.on("updateCountry", async (data: Country) => {
      const store = await this.fetchAll();
      store.forEach((s) => {
        if (s.country.id === data.id) {
          s.country = { ...data };
          this.update(s);
        }
      });
    });

    localStorageBackendBroker.on(
      "updateShippingBox",
      async (data: ShippingBox) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          let updateRequired = false;
          s.shippingBoxOffers.forEach((sb, idx) => {
            if (sb.shippingBox.id === data.id) {
              s.shippingBoxOffers[idx].shippingBox = { ...data };
              updateRequired = true;
            }
          });
          if (updateRequired) {
            this.update(s);
          }
        });
      }
    );

    localStorageBackendBroker.on(
      "updateBeanBag",
      async (data: BeanBag) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          let updateRequired = false;
          s.beanBagOffers.forEach((sb, idx) => {
            if (sb.beanBag.id === data.id) {
              s.beanBagOffers[idx].beanBag = { ...data };
              updateRequired = true;
            }
          });
          if (updateRequired) {
            this.update(s);
          }
        });
      }
    );

    localStorageBackendBroker.on(
      "updateBeanStrain",
      async (data: BeanStrain) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          let updateRequired = false;
          s.beanOffers.forEach((sb, idx) => {
            if (sb.beanStrain.id === data.id) {
              s.beanOffers[idx].beanStrain = { ...data };
              updateRequired = true;
            }
          });
          if (updateRequired) {
            this.update(s);
          }
        });
      }
    );
  }

  fixData(merchant: Merchant): Merchant {
    if (merchant.shippingBoxOffers === undefined) {
      merchant.shippingBoxOffers = [];
    }
    if (merchant.beanBagOffers === undefined) {
      merchant.beanBagOffers = [];
    }
    if (merchant.beanOffers === undefined) {
      merchant.beanOffers = [];
    }
    merchant.beanOffers.forEach((offer) => {
      if (offer.harvestDate) {
        offer.harvestDate = new Date(offer.harvestDate.toString());
      }
    });
    return merchant;
  }

  handlerWrapper(handler: (data: BackendType) => void) {
    return (data: BackendType): void => {
      const res = this.fixData(data);
      handler(res);
    };
  }

  async fetchAll(): Promise<BackendType[]> {
    const res = await this.backend.fetchAll();
    return res.map(this.fixData);
  }
  async add(data: BackendType): Promise<BackendType> {
    const res = await this.backend.addData(data);
    return this.fixData(res);
  }
  async update(data: BackendType): Promise<BackendType> {
    const res = await this.backend.updateData(data);
    return this.fixData(res);
  }
  async delete(data: BackendType): Promise<BackendType> {
    const res = await this.backend.deleteData(data);
    return this.fixData(res);
  }
  onAdded(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onAdded(this.handlerWrapper(handler));
  }
  onUpdated(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onUpdated(this.handlerWrapper(handler));
  }
  onRemoved(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onRemoved(this.handlerWrapper(handler));
  }

  private async getMerchant(merchantId: string): Promise<Merchant> {
    const merchant = (await this.fetchAll()).find((m) => m.id === merchantId);
    if (!merchant) {
      throw new Error("merchant not found");
    }
    return merchant;
  }

  async addBeanOffer(merchantId: string, data: BeanOffer): Promise<BeanOffer> {
    const copy = { ...data, id: v4() };
    const merchant = await this.getMerchant(merchantId);
    const merchantCopy: Merchant = {
      ...merchant,
      beanOffers: [...merchant.beanOffers, copy],
    };
    await this.update(merchantCopy);
    return copy;
  }

  async updateBeanOffer(
    merchantId: string,
    data: BeanOffer
  ): Promise<BeanOffer> {
    const copy = { ...data };
    const merchant = await this.getMerchant(merchantId);
    const offerIndex = merchant.beanOffers.findIndex((o) => o.id === copy.id);
    if (offerIndex < 0) {
      throw new Error("offer not found");
    }
    const merchantCopy: Merchant = {
      ...merchant,
      beanOffers: [...merchant.beanOffers],
    };
    merchantCopy.beanOffers[offerIndex] = copy;
    await this.update(merchantCopy);
    return copy;
  }

  async deleteBeanOffer(
    merchantId: string,
    data: BeanOffer
  ): Promise<BeanOffer> {
    const copy = { ...data };
    const merchant = await this.getMerchant(merchantId);
    const offerIndex = merchant.beanOffers.findIndex((o) => o.id === copy.id);
    if (offerIndex < 0) {
      throw new Error("offer not found");
    }
    const merchantCopy: Merchant = {
      ...merchant,
      beanOffers: [...merchant.beanOffers],
    };
    merchantCopy.beanOffers.splice(offerIndex, 1);
    await this.update(merchantCopy);
    return copy;
  }

  async addBeanBagOffer(
    merchantId: string,
    data: BeanBagOffer
  ): Promise<BeanBagOffer> {
    const copy = { ...data, id: v4() };
    const merchant = await this.getMerchant(merchantId);
    const merchantCopy: Merchant = {
      ...merchant,
      beanBagOffers: [...merchant.beanBagOffers, copy],
    };
    await this.update(merchantCopy);
    return copy;
  }

  async updateBeanBagOffer(
    merchantId: string,
    data: BeanBagOffer
  ): Promise<BeanBagOffer> {
    const copy = { ...data };
    const merchant = await this.getMerchant(merchantId);
    const offerIndex = merchant.beanBagOffers.findIndex(
      (o) => o.id === copy.id
    );
    if (offerIndex < 0) {
      throw new Error("offer not found");
    }
    const merchantCopy: Merchant = {
      ...merchant,
      beanBagOffers: [...merchant.beanBagOffers],
    };
    merchantCopy.beanBagOffers[offerIndex] = copy;
    await this.update(merchantCopy);
    return copy;
  }

  async deleteBeanBagOffer(
    merchantId: string,
    data: BeanBagOffer
  ): Promise<BeanBagOffer> {
    const copy = { ...data };
    const merchant = await this.getMerchant(merchantId);
    const offerIndex = merchant.beanBagOffers.findIndex(
      (o) => o.id === copy.id
    );
    if (offerIndex < 0) {
      throw new Error("offer not found");
    }
    const merchantCopy: Merchant = {
      ...merchant,
      beanBagOffers: [...merchant.beanBagOffers],
    };
    merchantCopy.beanBagOffers.splice(offerIndex, 1);
    await this.update(merchantCopy);
    return copy;
  }

  async addShippingBoxOffer(
    merchantId: string,
    data: ShippingBoxOffer
  ): Promise<ShippingBoxOffer> {
    const copy = { ...data, id: v4() };
    const merchant = await this.getMerchant(merchantId);
    const merchantCopy: Merchant = {
      ...merchant,
      shippingBoxOffers: [...merchant.shippingBoxOffers, copy],
    };
    await this.update(merchantCopy);
    return copy;
  }

  async updateShippingBoxOffer(
    merchantId: string,
    data: ShippingBoxOffer
  ): Promise<ShippingBoxOffer> {
    const copy = { ...data };
    const merchant = await this.getMerchant(merchantId);
    const offerIndex = merchant.shippingBoxOffers.findIndex(
      (o) => o.id === copy.id
    );
    if (offerIndex < 0) {
      throw new Error("offer not found");
    }
    const merchantCopy: Merchant = {
      ...merchant,
      shippingBoxOffers: [...merchant.shippingBoxOffers],
    };
    merchantCopy.shippingBoxOffers[offerIndex] = copy;
    await this.update(merchantCopy);
    return copy;
  }

  async deleteShippingBoxOffer(
    merchantId: string,
    data: ShippingBoxOffer
  ): Promise<ShippingBoxOffer> {
    const copy = { ...data };
    const merchant = await this.getMerchant(merchantId);
    const offerIndex = merchant.shippingBoxOffers.findIndex(
      (o) => o.id === copy.id
    );
    if (offerIndex < 0) {
      throw new Error("offer not found");
    }
    const merchantCopy: Merchant = {
      ...merchant,
      shippingBoxOffers: [...merchant.shippingBoxOffers],
    };
    merchantCopy.shippingBoxOffers.splice(offerIndex, 1);
    await this.update(merchantCopy);
    return copy;
  }
}

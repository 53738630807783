import { Table, Space, Progress, Typography } from "antd";
import { LocalStorageQualityLevelsBackend } from "ts/backends/qualityLevels/LocalStorageQualityLevelsBackend";
import { QualityLevel } from "ts/model/QualityLevel";
import { ConfirmationDeleteButton } from "tsx/pages/template/ConfirmationDeleteButton";
import { DefaultForm } from "tsx/pages/template/form/DefaultForm";
import { DefaultFormProps } from "tsx/pages/template/form/defaultFormProps";
import { OrdinalInput } from "tsx/pages/template/form/OrdinalInput";
import { TranslatedValueInput } from "tsx/pages/template/form/TranslatedValueInput";
import { UpsertModal } from "tsx/pages/template/form/UpsertModal";
import { getBackendHooks } from "tsx/pages/template/hooks/useAttachBackendHandler";
import { MultiLanguageOutput } from "tsx/pages/template/list/MultiLanguageOutput";

const backend = new LocalStorageQualityLevelsBackend();
const queryKey = ["qualityLevels"];
type usedType = QualityLevel;
const initial = new QualityLevel("", "0", { german: "", english: "" }, 0);
const usedTypeName = "Quality Level";

export const qualityLevelsHooks = getBackendHooks(
  {
    add: backend.add.bind(backend),
    delete: backend.delete.bind(backend),
    fetch: backend.fetchAll.bind(backend),
    onAdd: backend.onAdded.bind(backend),
    onDelete: backend.onRemoved.bind(backend),
    onUpdate: backend.onUpdated.bind(backend),
    update: backend.update.bind(backend),
  },
  queryKey
);

const DataForm = (props: DefaultFormProps<usedType>) => {
  return (
    <DefaultForm {...props}>
      <TranslatedValueInput path={["name"]} label="Name" required />
      <OrdinalInput path="ordinal" label="Ordinal" />
    </DefaultForm>
  );
};

export const Create = () => {
  const insert = qualityLevelsHooks.useInsert();
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={insert}
    />
  );
};

const Update = (props: { data: usedType }) => {
  const action = qualityLevelsHooks.useUpdate();
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const QualityLevelsList = () => {
  const data = qualityLevelsHooks.useData();
  const deleteData = qualityLevelsHooks.useDelete();
  return (
    <Table
      loading={data.isLoading}
      dataSource={data.data}
      columns={[
        {
          title: "Name",
          render: (val: usedType) => (<MultiLanguageOutput val={val.name} />),
        },
        {
          title: "Ordinal",
          render: (val: usedType) => {
            return (
              <Space>
                <Typography.Text>{val.ordinal}/100</Typography.Text>
                <Progress
                  percent={val.ordinal}
                  showInfo={false}
                  size="small"
                  status="normal"
                  style={{ width: 80 }}
                />
              </Space>
            );
          },
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

import { Table, Space } from "antd";
import { LocalStorageBeanTypesBackend } from "ts/backends/beanTypes/LocalStorageBeanTypesBackend";
import { BeanType } from "ts/model/BeanType";
import { ConfirmationDeleteButton } from "tsx/pages/template/ConfirmationDeleteButton";
import { DefaultForm } from "tsx/pages/template/form/DefaultForm";
import { DefaultFormProps } from "tsx/pages/template/form/defaultFormProps";
import { TranslatedValueInput } from "tsx/pages/template/form/TranslatedValueInput";
import { UpsertModal } from "tsx/pages/template/form/UpsertModal";
import { getBackendHooks } from "tsx/pages/template/hooks/useAttachBackendHandler";
import { MultiLanguageOutput } from "tsx/pages/template/list/MultiLanguageOutput";

const initial = new BeanType("", "0", { german: "", english: "" });

const backend = new LocalStorageBeanTypesBackend();
const queryKey = ["beanTypes"];

export const beanTypesHooks = getBackendHooks(
  {
    add: backend.add.bind(backend),
    delete: backend.delete.bind(backend),
    fetch: backend.fetchAll.bind(backend),
    onAdd: backend.onAdded.bind(backend),
    onDelete: backend.onRemoved.bind(backend),
    onUpdate: backend.onUpdated.bind(backend),
    update: backend.update.bind(backend),
  },
  queryKey
);


export const BeanTypeForm = (props: DefaultFormProps<BeanType>) => {
  return (
      <DefaultForm {...props}>
        <TranslatedValueInput path={["name"]} label="Name" required />
      </DefaultForm>
  );
};


export const CreateBeanType = () => {
  const insert = beanTypesHooks.useInsert();
  return (
    <UpsertModal
      title={"Create Bean Type"}
      mode="insert"
      initial={initial}
      form={BeanTypeForm}
      upsertAction={insert}
    />
  );
};


export const UpdateBeanType = (props: { beanType: BeanType }) => {
  const action = beanTypesHooks.useUpdate();
  return (
    <UpsertModal
      title={"Update Bean Type"}
      hideButtonText
      mode="update"
      initial={props.beanType}
      form={BeanTypeForm}
      upsertAction={action}
    />
  );
};


export const BeanTypesList = () => {
  const deleteBeanType = beanTypesHooks.useDelete();
  const beanTypes = beanTypesHooks.useData();
  return (
    <Table
      dataSource={beanTypes.data}
      columns={[
        {
          title: "Name Deutsch",
          dataIndex: ["name", "german"],
        },
        {
          title: "Name English",
          render: (val: BeanType) => (<MultiLanguageOutput val={val.name} />),
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (beanType: BeanType) => (
            <Space>
              <UpdateBeanType beanType={beanType} />
              <ConfirmationDeleteButton
                onOk={() => deleteBeanType.mutate(beanType)}
              />
            </Space>
          ),
        },
      ]}
      rowKey={(bean: BeanType) => bean.id}
    />
  );
};


import { CenteredLayout } from "../../template/CenteredLayout";
import { AcidityLevelsList, Create } from "./AcidityLevelsList";


export function AcidityLevels() {
  return (
    <CenteredLayout title="Acidity Levels" extra={[<Create key="create" />]}>
      <AcidityLevelsList />
    </CenteredLayout>
  );
}

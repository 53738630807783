import { useStoredBeansBatches } from "./hooks/useStoredBeansBatches";

export const ImagePrefetcher = () => {
  const beanStorage = useStoredBeansBatches();

  return (
    <>
      {beanStorage.data?.map((b) => (
        <link rel="prefetch" href={b.imageLink} />
      ))}
    </>
  );
};

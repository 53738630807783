import { DateFactory } from "ts/DateFactory";
import { AcidityLevel } from "ts/model/AcidityLevel";
import { Aftertaste } from "ts/model/Aftertaste";
import { BeanFarm } from "ts/model/BeanFarm";
import { BeanStrain } from "ts/model/BeanStrain";
import { BeanType } from "ts/model/BeanType";
import { BodyLevel } from "ts/model/BodyLevel";
import { Country } from "ts/model/Country";
import { Flavor } from "ts/model/Flavor";
import { IntensityLevel } from "ts/model/IntensityLevel";
import { ProcessingType } from "ts/model/ProcessingType";
import { QualityLevel } from "ts/model/QualityLevel";
import { SweetnessLevel } from "ts/model/SweetnessLevel";
import { TasteProfile } from "ts/model/TasteProfile";
import { TranslatedValue } from "ts/model/TranslatedValue";

import { WebsocketConnection } from "./WebsocketConnection";

export class StoredBeanBatch {
  constructor(
    readonly id: string,
    public mvccId: string,
    public beanStrain: BeanStrain,
    public harvestDate: Date,
    public quality: QualityLevel,
    public amountInKg: number,
    public imageLink: string,
    public notes?: TranslatedValue
  ) {}
}

export class StoredBeanBatchBackend {
  private readonly mockedReturn: StoredBeanBatch[];
  constructor(websocketConnection?: WebsocketConnection) {
    const arabica = new BeanType("1", "1", {
      german: "Arabica",
      english: "Arabica",
    });
    const washed = new ProcessingType("1", "1", {
      german: "gewaschen",
      english: "washed",
    });
    const brazil = new Country("1", "1", {
      german: "Brasilien",
      english: "Brazil",
    });
    const lowAcidity = new AcidityLevel(
      "1",
      "1",
      { german: "Niedrig", english: "low" },
      50
    );
    const highSweetness = new SweetnessLevel(
      "1",
      "1",
      { german: "Süß", english: "sweet" },
      50
    );
    const mediumBody = new BodyLevel(
      "1",
      "1",
      { german: "Mittel", english: "medium" },
      50
    );
    const mediumIntensity = new IntensityLevel(
      "1",
      "1",
      { german: "Mittel", english: "medium" },
      50
    );
    const mediumAftertaste = new Aftertaste(
      "1",
      "1",
      { german: "Mittel", english: "medium" },
      50
    );
    const qualityLevelSuperPremium = new QualityLevel(
      "1",
      "1",
      { german: "Super Premium", english: "Super Premium" },
      100
    );
    const flavors = [
      new Flavor("1", "1", { german: "Vanille", english: "vanilla" }),
      new Flavor("2", "1", {
        german: "Milchschoki",
        english: "milk chocolate",
      }),
      new Flavor("3", "1", { german: "Meyer-Zitrone", english: "meyer lemon" }),
    ];
    this.mockedReturn = [
      new StoredBeanBatch(
        "1",
        "1",
        new BeanStrain(
          "1",
          "1",
          "Brazil Yellow Bourbon",
          arabica,
          washed,
          new BeanFarm(
            "",
            "",
            "Fazenda Mariano",
            "Poços de Caldas",
            "São Domingos Mountain - South Minas Gerais",
            brazil
          ),
          new TasteProfile(
            {
              german: "Kackig, Vanille, Meier Zitrone, Milchschoki",
              english:
                "Overall: Crisp, sweet, vanilla, meyer lemon, milk chocolate",
            },
            lowAcidity,
            highSweetness,
            mediumBody,
            mediumIntensity,
            mediumAftertaste,
            flavors
          )
        ),
        DateFactory.startOfDayInUtc(2021, 6, 1),
        qualityLevelSuperPremium,
        2,
        "https://beangineers.de/beans/BrazilYellowBourbon.jpg"
      ),
      new StoredBeanBatch(
        "2",
        "1",
        new BeanStrain(
          "1",
          "1",
          "Indian Monsooned Malabar",
          arabica,
          washed,
          new BeanFarm(
            "",
            "",
            "Fazenda Mariano",
            "Poços de Caldas",
            "São Domingos Mountain - South Minas Gerais",
            brazil
          ),
          new TasteProfile(
            { german: "", english: "" },
            lowAcidity,
            highSweetness,
            mediumBody,
            mediumIntensity,
            mediumAftertaste,
            flavors
          )
        ),
        DateFactory.startOfDayInUtc(2021, 6, 1),
        qualityLevelSuperPremium,
        2,
        "https://beangineers.de/beans/IndienMonsoonedCanephoraRobustaSangameshwar.jpg"
      ),
      new StoredBeanBatch(
        "3",
        "1",
        new BeanStrain(
          "1",
          "1",
          "Brazil Santos NY2 17/18 entcoffeiniert",
          arabica,
          washed,
          new BeanFarm(
            "",
            "",
            "Fazenda Mariano",
            "Poços de Caldas",
            "São Domingos Mountain - South Minas Gerais",
            brazil
          ),
          new TasteProfile(
            { german: "", english: "" },
            lowAcidity,
            highSweetness,
            mediumBody,
            mediumIntensity,
            mediumAftertaste,
            flavors
          )
        ),
        DateFactory.startOfDayInUtc(2021, 6, 1),
        qualityLevelSuperPremium,
        2,
        "https://beangineers.de/beans/BrazilSantosEntcoffeiniert .jpg"
      ),
      new StoredBeanBatch(
        "4",
        "1",
        new BeanStrain(
          "1",
          "1",
          "Äthiopia Sidamo Bio ",
          arabica,
          washed,
          new BeanFarm(
            "",
            "",
            "Fazenda Mariano",
            "Poços de Caldas",
            "São Domingos Mountain - South Minas Gerais",
            brazil
          ),
          new TasteProfile(
            { german: "", english: "" },
            lowAcidity,
            highSweetness,
            mediumBody,
            mediumIntensity,
            mediumAftertaste,
            flavors
          )
        ),
        DateFactory.startOfDayInUtc(2021, 6, 1),
        qualityLevelSuperPremium,
        2,
        "https://beangineers.de/beans/AethiopiaSidamoBio.jpg"
      ),
      new StoredBeanBatch(
        "5",
        "1",
        new BeanStrain(
          "1",
          "1",
          "Malawi AA Plus Pamwambar",
          arabica,
          washed,
          new BeanFarm(
            "1",
            "1",
            "Fazenda Mariano",
            "Poços de Caldas",
            "São Domingos Mountain - South Minas Gerais",
            brazil
          ),
          new TasteProfile(
            { german: "", english: "" },
            lowAcidity,
            highSweetness,
            mediumBody,
            mediumIntensity,
            mediumAftertaste,
            flavors
          )
        ),
        DateFactory.startOfDayInUtc(2021, 6, 1),
        qualityLevelSuperPremium,
        2.555555568,
        "https://beangineers.de/beans/Malawi.jpg"
      ),
    ];
    const eventPrefix = "de.beangineers.events.public.storedBeanBatch";
    //websocketConnection.register(new EventTypeId(`${eventPrefix}.BeanAdded`), { handleEvent: (event: RawWebsocketEvent) => { this.beanAdded.next(event.castTo()) } })
    //websocketConnection.register(new EventTypeId(`${eventPrefix}.createBeanSuccess`), { handleEvent: (event: RawWebsocketEvent) => { } })
  }

  fetchAll(): Promise<StoredBeanBatch[]> {
    return Promise.resolve<StoredBeanBatch[]>(
      JSON.parse(JSON.stringify(this.mockedReturn))
    );
  }
}

import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useRef, useState } from "react";
import { UseMutationResult } from "react-query/types/react/types";
import { v4 } from "uuid";
import { useFormErrorMarker } from "../hooks/useFormErrorMarker";
import { modalLayout } from "../modalLayouts";
import { DefaultFormProps } from "./defaultFormProps";

export const UpsertModal = <T extends object>(props: {
  title: string;
  initial: T;
  mode: "insert" | "update";
  hideButtonText?: boolean;
  form: (props: DefaultFormProps<T>) => JSX.Element;
  upsertAction: UseMutationResult<T, any, T, unknown>;
}) => {
  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const errorMarker = useFormErrorMarker(form);
  let okHandler: () => any;
  let resetHandler: () => any;
  const formId = useRef(v4());
  const cancelHandler = () => {
    resetHandler();
    setVisible(false);
  };
  return (
    <>
      <Button
        icon={props.mode === "insert" ? <PlusOutlined /> : <EditOutlined />}
        loading={props.upsertAction.isLoading}
        onClick={() => {
          setVisible(true);
        }}
      >
        {!props.hideButtonText && props.title}
      </Button>
      <Modal
        {...modalLayout}
        visible={visible}
        confirmLoading={props.upsertAction.isLoading}
        onOk={() => okHandler()}
        title={props.title}
        onCancel={() => cancelHandler()}
        footer={[
          <Button
            form={formId.current}
            key="submit"
            htmlType="submit"
            type="primary"
            loading={props.upsertAction.isLoading}
          >
            Ok
          </Button>,
          <Button
            key="cancel"
            onClick={() => cancelHandler()}
            disabled={props.upsertAction.isLoading}
          >
            Cancel
          </Button>,
        ]}
      >
        <props.form
          id={formId.current}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          initial={{ ...props.initial }}
          onFinish={(ps: any) => {
            return new Promise((resolve, reject) => {
              props.upsertAction
                .mutateAsync(ps)
                .then(() => {
                  setVisible(false);
                  resolve();
                })
                .catch((error) => {
                  errorMarker(error);
                  reject();
                });
              return;
            });
          }}
          form={form}
        />
      </Modal>
    </>
  );
};

import produce, { immerable } from "immer"
import { Bean } from "../ts/backends/BeansBackend"

export class BeansState {
    [immerable] = true
    constructor(public data: Map<String, Bean>, public isLoading: boolean) { }

    static initial(): BeansState {
        return new BeansState(new Map(), false)
    }
}

export class BeansStore {
    constructor(readonly state: BeansState, readonly dispatch: any) { 
    
    }
    
    static emptyStore(): BeansStore {
        return new BeansStore(new BeansState(new Map(), false), {}, )
    }

    beansArray() {
        return Array.from(this.state.data.values())
    }
}

interface Executor { (state: BeansState, draft: BeansState): void }

export class BeansReducerAction {
    constructor(readonly executor: Executor) { }

    handle(state: BeansState): BeansState {
        return produce<BeansState>(state, draft => {
            this.executor(state, draft)
        })
    }
}
export const AddBean = (bean: Bean) => new BeansReducerAction((state: BeansState, draft: BeansState) => { draft.data.set(bean.id, bean) })
export const UpdateBean = (bean: Bean) => new BeansReducerAction((state: BeansState, draft: BeansState) => { draft.data.set(bean.id, bean) })
export const RemoveBean = (bean: Bean) => new BeansReducerAction((state: BeansState, draft: BeansState) => { draft.data.delete(bean.id) })
export const SetBeans = (beans: Array<Bean>) => new BeansReducerAction((state: BeansState, draft: BeansState) => {
    draft.data = beans.reduce((map, bean) => { map.set(bean.id, bean); return map; }, new Map())
})

export const BeansReducer = (state: BeansState, action: BeansReducerAction) => { return action.handle(state) }
import { TranslatedValue } from "./TranslatedValue";


export class Flavor {
  constructor(
    readonly id: string,
    public mvccId: string,
    public name: TranslatedValue
  ) { }
}

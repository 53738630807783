import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useRef } from "react";
import { formItemLayout } from "../formLayouts";
import { DefaultFormProps } from "./defaultFormProps";
import { FormUpdateHandler } from "./formUpdateHandler";

export const DefaultForm = <T extends object>(
  props: DefaultFormProps<T> & { children: React.ReactNode }
) => {
  const [form] = useForm(props.form);
  const canceller = useRef<FormUpdateHandler>();
  props.ok(() => {
    form.submit();
  });
  props.reset(() => {
    form.resetFields();
    if (canceller.current) {
      canceller.current();
    }
  });
  return (
    <>
      <Form
        id={props.id}
        {...formItemLayout}
        labelAlign="left"
        name="basic"
        initialValues={{ ...props.initial }}
        scrollToFirstError
        form={props.form || form}
        onFinish={async (value) => {
          const copy = Object.assign({ ...props.initial }, { ...value }) as T;
          await props.onFinish(copy);
          if (canceller.current) {
            canceller.current();
          }
          form.resetFields();
        }}
      >
        {props.children}
      </Form>
    </>
  );
};

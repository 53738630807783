import { useQuery } from "react-query";

type RoastsRaw = {
  hits: {
    hits: {
      _id: string;
      _source: {
        url: string;
        beanId: string;
        roastName: string;
        weightGreen: number;
        weightRoasted: number;
        totalRoastTime: number;
        dateTime: number;
        preheatTemperature: number;
        roastDegree: number;
        firstCrackTime: number;
        firstCrackTemp: number;
        secondCrackTime: number;
        secondCrackTemp: number;
      };
    }[];
  };
};

export type RoastworldRoast = {
  id: string;
  detailsUrl: string;
  roastName: string;
  weightGreen: number;
  weightRoasted: number;
  totalRoastTime: number;
  dateTime: Date;
  preheatTemperature: number;
  roastDegree: number;
  firstCrackTime: number;
  firstCrackTemp: number;
  secondCrackTime: number;
  secondCrackTemp: number;
};

export const useRoastworldRoasts = () => {
  return useQuery<RoastworldRoast[]>(
    ["roastsExt"],
    async () => {
      // const roastsRaw = require("./roasts.json") as RoastsRaw;

      const resp = await fetch(
        "https://8aaaf5aea57b402491a37fcd426a1b7d.ap-northeast-1.aws.found.io:9243/roasts/_search",
        {
          mode: "cors",
          headers: {
            authorization: "Basic YWlsbGlvOmFpbGxpMGcwZXNnMGxk",
            "content-type": "application/json",
          },
          body: '{"size":100000,"from":0,"query":{"bool":{"must":[{"match":{"deleted":0}},{"match":{"userId.keyword":"5AbOSJtuyVTRWS3I3uXUxVPMmF13"}}]}},"sort":{"dateTime":"desc"}}',
          method: "POST",
        }
      );
      const json = (await resp.json()) as RoastsRaw;
      const mapped: RoastworldRoast[] = json.hits.hits.map((r) => ({
        dateTime: new Date(r._source.dateTime),
        detailsUrl: r._source.url,
        firstCrackTemp: r._source.firstCrackTemp,
        firstCrackTime: r._source.firstCrackTime,
        id: r._id,
        preheatTemperature: r._source.preheatTemperature,
        roastDegree: r._source.roastDegree,
        roastName: r._source.roastName,
        secondCrackTemp: r._source.secondCrackTemp,
        secondCrackTime: r._source.secondCrackTime,
        totalRoastTime: r._source.totalRoastTime,
        weightGreen: r._source.weightGreen,
        weightRoasted: r._source.weightRoasted,
      }));
      return Promise.resolve(mapped);
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};

import { Table, Space, Typography } from "antd";
import { ConfirmationDeleteButton } from "tsx/pages/template/ConfirmationDeleteButton";
import { DefaultForm } from "tsx/pages/template/form/DefaultForm";
import { DefaultFormProps } from "tsx/pages/template/form/defaultFormProps";
import { ObjectSelectInput } from "tsx/pages/template/form/ObjectSelectInput";
import { UpsertModal } from "tsx/pages/template/form/UpsertModal";
import { PriceInput } from "tsx/pages/template/form/PriceInput";
import { StringInput } from "tsx/pages/template/form/StringInput";
import {
  useCreateBeanBagOffer,
  useDeleteBeanBagOffer,
  useUpdateBeanBagOffer,
} from "./beanMerchantsHooks";
import { BeanBagOffer } from "ts/model/BeanBagOffer";
import { BeanBag } from "ts/model/BeanBag";
import { beanBagsHooks } from "tsx/pages/shipping/beanBags/BeanBagsHooks";

type usedType = BeanBagOffer;
const initial = new BeanBagOffer("", {} as BeanBag, 0.0, "");
const usedTypeName = "Bean Bag Offer";

const DataForm = (props: DefaultFormProps<usedType>) => {
  const bags = beanBagsHooks.useData();
  // TODO: offers!
  return (
    <DefaultForm {...props}>
      <ObjectSelectInput
        form={props.form!}
        label="Bean Bag"
        loading={bags.isLoading}
        source={bags.data}
        path={["beanBag"]}
        required
      />
      <PriceInput label="Price/Unit" path="pricePerUnitInEuro" />
      <StringInput path={"notes"} label={"Notes"} required={false} />
    </DefaultForm>
  );
};

export const Create = (props: { merchantId: string }) => {
  const action = useCreateBeanBagOffer(props.merchantId);
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={action}
    />
  );
};

const Update = (props: { data: usedType; merchantId: string }) => {
  const action = useUpdateBeanBagOffer(props.merchantId);
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const BeanBagOffersList = (props: {
  offers: usedType[];
  merchantId: string;
}) => {
  const deleteData = useDeleteBeanBagOffer(props.merchantId);
  return (
    <Table
      title={() => <Typography.Title level={3}>Bean Bag Offers</Typography.Title>}
      footer={() => <Create key="add" merchantId={props.merchantId} />}
      dataSource={props.offers}
      columns={[
        {
          title: "Bean Bag",
          dataIndex: ["beanBag", "name"],
        },
        {
          title: "Price",
          render: (val: usedType) => `${val.pricePerUnitInEuro}€/Unit`,
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} merchantId={props.merchantId} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

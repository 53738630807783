import { BeanStrains } from "./beanStrains/BeanStrains";
import { BeanTypes } from "./beanTypes/BeanTypes";
import { ProcessingTypes } from "./processingTypes/ProcessingTypes";
import { QualityLevels } from "./qualityLevels/QualityLevels";

export const BeansPage = () => {
  return (
    <>
      <BeanTypes />
      <ProcessingTypes />
      <QualityLevels />
      <BeanStrains />
    </>
  );
};


import { CenteredLayout } from "../../template/CenteredLayout";
import { BeanStrainsList, Create } from "./BeanStrainsList";


export function BeanStrains() {
  return (
    <CenteredLayout title="Bean Strains" extra={[<Create key="create" />]}>
      <BeanStrainsList />
    </CenteredLayout>
  );
}

export const landingSectionStyle = {
  paddingTop: 64,
  paddingBottom: 128,
  minHeight: 460,
  paddingLeft: 16,
  paddingRight: 16,
  overflow: "hidden",
};

export const wrapper3 = {
  xxl: { span: 14, offset: 5 },
  md: { span: 24, offset: 0 },
};

export const content3 = {
    md: 8,
    sm: 24,
}

export const wrapper1 ={
    md: {span: 14, offset: 5},
    lg: {span: 10, offset: 7},
    xl: {span: 8, offset: 8},
    xs: {span: 24, offset: 0}
}

export const contentFull = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 6,
    xxl: 4
}

export const gutter = { xs: 8, sm: 16, md: 64}
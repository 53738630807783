const lgLabelWidth = 7;
const mdLabelWidth = 10;
const smLabelWidth = 12;
export const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: smLabelWidth },
        md: { span: mdLabelWidth },
        lg: { span: lgLabelWidth },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 - smLabelWidth },
        md: { span: 24 - mdLabelWidth },
        lg: { span: 24 - lgLabelWidth },
    },
};
export const inlineFormItemLayout = {
    labelCol: { xs: { span: 0 } },
    wrapperCol: { xs: { span: 24 } },
};
export const nestedFormItemLayout = {
    labelCol: { xs: { span: 0 } },
    wrapperCol: {
        lg: { span: 24 - lgLabelWidth, push: lgLabelWidth },
    },
};
import axios from "axios";
import { useQuery } from "react-query";
import { RoastworldRoast } from "./useRoastworldRoasts";

export type DetailedRoastworldRoast = RoastworldRoast & {
  beanChargeTemperature: number;
  beanDropTemperature: number;
  drumChargeTemperature: number;
  drumDropTemperature: number;
  beanTemperature: number[];
  drumTemperature: number[];
  beanDerivative: number[];
  ibtsDerivative: number[];
  exitTemperature: number[];
  roastStartIndex: number;
  roastEndIndex: number;
  indexFirstCrackStart: number;
  indexFirstCrackEnd: number;
  indexSecondCrackStart: number;
  indexSecondCrackEnd: number;
  indexYellowingStart: number;
  roastNumber: number;
  sampleRate: number;
  ambient: number;
  humidity: number;
};

export const useRoastworldRoastDetails = (roast?: RoastworldRoast) => {
  return useQuery(
    ["roastworldRoast", roast?.id],
    async () => {
      const detailsRaw = await axios.get(roast?.detailsUrl || "");
      console.log(detailsRaw.data);

      const ret: DetailedRoastworldRoast = { ...roast!, ...detailsRaw.data };
      ret.firstCrackTemp = ret.firstCrackTemp / 10;
      ret.weightGreen = parseFloat(ret.weightGreen.toString());
      ret.weightRoasted = parseFloat(ret.weightRoasted.toString());

      return Promise.resolve(ret);
    },
    {
      enabled: (roast && roast.id !== "") || false,
    }
  );
};

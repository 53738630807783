import { Countries } from "./countries/Countries";
import { BeanFarms } from "./farms/BeanFarms";
import { Merchants } from "./merchants/Merchants";

export function Vendors() {
  return (
    <>
      <Countries />
      <BeanFarms />
      <Merchants />
    </>
  );
}

import React, { useContext, FunctionComponent, useReducer, useState, useEffect } from "react"
import { act } from "react-dom/test-utils"

type RemainingIdleTimeProps = {}
export const RemainingIdleTime: FunctionComponent<RemainingIdleTimeProps> = ({ children }) => {
    const contextValue = useContext(ActivityTimerContext)
    const minutes = contextValue.remainingMinutesPart
    const seconds = contextValue.remainingSecondsPart
    return (
        <div id="RemainingIdleTime" data-testid="RemainingIdleTime">
            {minutes} : {seconds}
        </div>
    )
}


type Props = {}
class ActivityTimerState {
    constructor(readonly remainingSeconds: number) { }
}
export class ActivityTimerContextContent {
    readonly remainingInS: number
    readonly remainingMinutesPart: number
    readonly remainingSecondsPart: number
    constructor(
        readonly remainingInMS: number
    ) {
        this.remainingInS = this.remainingInMS / 1000
        this.remainingMinutesPart = Math.floor(this.remainingInS / 60)
        this.remainingSecondsPart = Math.floor(this.remainingInS - this.remainingMinutesPart * 60)
    }
}

export const ActivityTimerContext = React.createContext(new ActivityTimerContextContent(0))

const events = [
    'mousedown',
    // 'mousemove', 'onkeydown', 'scroll', 'touchstart'
];
class ActivityState {
    constructor(private lastActivityTimestamp: number) { }

    private shouldBackOffToSaveResources() {
        const shouldBackOff = Date.now() - this.lastActivityTimestamp < 1000
        return shouldBackOff
    }
    handleEventRegistrations(setLastActivityState: (newState: ActivityState) => void) {
        // if (this.lastActivityTimestamp) {
        //     events.forEach(function (name) {
        //         console.log("unsubscribe")
        //         document.removeEventListener(name, (e: Event) => { updateLastActivityState(setLastActivityState) }, true);
        //     });
        // }
    }
}
const updateLastActivityState = (setLastActivityState: (newState: ActivityState) => void) => {
    console.log("update last activity state")
}

type ContextState = {
    current: ActivityTimerContextContent
}
export const ActivityTimerContextProvider: FunctionComponent<Props> = ({ children }) => {
    const [lastActivityState, setLastActivityState] = useState<ContextState>({ current: new ActivityTimerContextContent(10000) })
    // console.log("Render Context", lastActivityState.current)
    useEffect(() => {
        console.log("subscribe")
        events.forEach(function (name) {
            console.log("addListener ", name)
            document.addEventListener(name, (e: Event) => {
                setLastActivityState(prevState => {
                    if (prevState.current.remainingInMS === 0) {
                        const interval = setInterval(() => {
                            setLastActivityState(prevState => {
                                const nextVal = prevState.current.remainingInMS < 1000 ? 0 : prevState.current.remainingInMS - 1000
                                if (nextVal === 0) {
                                    clearInterval(interval)
                                }
                                return { current: new ActivityTimerContextContent(nextVal) }
                            })
                        }, 1000)
                    }
                    return { current: new ActivityTimerContextContent(9000) }
                })
            }, true);
        });
        const interval = setInterval(() => {
            setLastActivityState(prevState => {
                const nextVal = prevState.current.remainingInMS < 1000 ? 0 : prevState.current.remainingInMS - 1000
                if (nextVal === 0) {
                    clearInterval(interval)
                }
                return { current: new ActivityTimerContextContent(nextVal) }
            })
        }, 1000)
        return () => {
            console.log("unsubscribe")
            clearInterval(interval)
            events.forEach(function (name) {
                document.removeEventListener(name, (e: Event) => { setLastActivityState({ current: new ActivityTimerContextContent(310000) }) }, true);
            });
        }
    }, [setLastActivityState])

    return (
        <ActivityTimerContext.Provider value={lastActivityState.current}>
            {children}
        </ActivityTimerContext.Provider>
    )
}


export interface Transition {
    from: string;
    to: string;
    action: string;
}
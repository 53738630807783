import { LocalStorageBeanBagsBackend } from "ts/backends/beanBags/LocalStorageBeanBagsBackend";
import { getBackendHooks } from "tsx/pages/template/hooks/useAttachBackendHandler";
import { queryKey } from "./BeanBagsList";

export const backend = new LocalStorageBeanBagsBackend();
export const beanBagsHooks = getBackendHooks(
  {
    add: backend.add.bind(backend),
    delete: backend.delete.bind(backend),
    fetch: backend.fetchAll.bind(backend),
    onAdd: backend.onAdded.bind(backend),
    onDelete: backend.onRemoved.bind(backend),
    onUpdate: backend.onUpdated.bind(backend),
    update: backend.update.bind(backend),
  },
  queryKey
);

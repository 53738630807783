import { Table, Space } from "antd";
import { LocalStorageProcessingTypesBackend } from "ts/backends/processingTypes/LocalStorageProcessingTypesBackend";
import { ConfirmationDeleteButton } from "../../template/ConfirmationDeleteButton";
import { DefaultForm } from "../../template/form/DefaultForm";
import { DefaultFormProps } from "../../template/form/defaultFormProps";
import { TranslatedValueInput } from "../../template/form/TranslatedValueInput";
import { UpsertModal } from "../../template/form/UpsertModal";
import { getBackendHooks } from "../../template/hooks/useAttachBackendHandler";
import { ProcessingType } from "ts/model/ProcessingType";
import { MultiLanguageOutput } from "tsx/pages/template/list/MultiLanguageOutput";

const backend = new LocalStorageProcessingTypesBackend();
const queryKey = ["processingTypes"];
type usedType = ProcessingType;
const initial = new ProcessingType("", "0", { german: "", english: "" });
const usedTypeName = "ProcessingType";

export const processingTypesHooks = getBackendHooks(
  {
    add: backend.add.bind(backend),
    delete: backend.delete.bind(backend),
    fetch: backend.fetchAll.bind(backend),
    onAdd: backend.onAdded.bind(backend),
    onDelete: backend.onRemoved.bind(backend),
    onUpdate: backend.onUpdated.bind(backend),
    update: backend.update.bind(backend),
  },
  queryKey
);

const DataForm = (props: DefaultFormProps<usedType>) => {
  return (
    <DefaultForm {...props}>
      <TranslatedValueInput path={["name"]} label="Name" required />
    </DefaultForm>
  );
};

export const Create = () => {
  const insert = processingTypesHooks.useInsert();
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={insert}
    />
  );
};

const Update = (props: { data: usedType }) => {
  const action = processingTypesHooks.useUpdate();
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const ProcessingTypesList = () => {
  const data = processingTypesHooks.useData();
  const deleteData = processingTypesHooks.useDelete();
  return (
    <Table
      loading={data.isLoading}
      dataSource={data.data}
      columns={[
        {
          title: "Name",
          render: (val: usedType) => (<MultiLanguageOutput val={val.name} />),
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

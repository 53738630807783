
import { CenteredLayout } from "../../template/CenteredLayout";
import { BeanFarmsList, Create } from "./BeanFarmsList";


export function BeanFarms() {
  return (
    <CenteredLayout title="Bean Farms" extra={[<Create key="create" />]}>
      <BeanFarmsList />
    </CenteredLayout>
  );
}

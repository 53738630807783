
import { CenteredLayout } from "../../template/CenteredLayout";
import { CountriesList, Create } from "./CountriesList";


export function Countries() {
  return (
    <CenteredLayout title="Countries" extra={[<Create key="create" />]}>
      <CountriesList />
    </CenteredLayout>
  );
}

import { LocalStorageShippingBoxesBackend } from "ts/backends/shippingBox/LocalStorageShippingBoxesBackend";
import { getBackendHooks } from "tsx/pages/template/hooks/useAttachBackendHandler";
import { queryKey } from "./ShippingBoxesList";

export const backend = new LocalStorageShippingBoxesBackend();
export const shippingBoxesHooks = getBackendHooks(
  {
    add: backend.add.bind(backend),
    delete: backend.delete.bind(backend),
    fetch: backend.fetchAll.bind(backend),
    onAdd: backend.onAdded.bind(backend),
    onDelete: backend.onRemoved.bind(backend),
    onUpdate: backend.onUpdated.bind(backend),
    update: backend.update.bind(backend),
  },
  queryKey
);

import { Row, Col } from "antd";
import { FunctionComponent } from "react";
import * as React from "react";
import { PageTitle } from "./PageTitle";

type CenteredLayoutProps = {
  title?: string;
  subtitle?: string;
  style?: any;
  extra?: React.ReactNode[];
};

export const CenteredLayout: FunctionComponent<CenteredLayoutProps> = ({
  children,
  title,
  subtitle,
  extra,
  ...rest
}) => {
  return (
    <Row justify="center" {...rest} style={{ marginTop: 24 }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16}>
        {title && <PageTitle title={title} subtitle={subtitle} extra={extra} />}
        <div style={{ marginLeft: 24, marginRight: 24 }}>{children}</div>
      </Col>
    </Row>
  );
};

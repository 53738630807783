import { TranslatedValue } from "./TranslatedValue";
import { AcidityLevel } from "./AcidityLevel";
import { SweetnessLevel } from "./SweetnessLevel";
import { BodyLevel } from "./BodyLevel";
import { IntensityLevel } from "./IntensityLevel";
import { Flavor } from "./Flavor";
import { Aftertaste } from "./Aftertaste";


export class TasteProfile {
  constructor(
    public description: TranslatedValue,
    public acidity: AcidityLevel,
    public sweetness: SweetnessLevel,
    public body: BodyLevel,
    public intensity: IntensityLevel,
    public afterTaste: Aftertaste,
    public flavors: Flavor[]
  ) { }
}

import { FunctionComponent, createContext, useReducer } from 'react';
import { BeansReducer, BeansState, BeansStore } from './Beans';


class StoreContextContent {
    constructor(
        readonly beansStore: BeansStore
    ) {

    }

    static initial(): StoreContextContent {
        return new StoreContextContent(BeansStore.emptyStore())
    }
}

export const StoreContext = createContext(StoreContextContent.initial());

type StoreProps = {}
export const Store: FunctionComponent<StoreProps> = (props) => {
    const [beansState, beansDispatcher] = useReducer(BeansReducer, BeansState.initial());
    const storeContext = new StoreContextContent(new BeansStore(beansState, beansDispatcher))

    return (
        <StoreContext.Provider value={storeContext}>
            {props.children}
        </StoreContext.Provider>
    )
}

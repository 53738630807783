import { TranslatedValue } from "./TranslatedValue";
import { Country } from "./Country";
import { BeanOffer } from "./BeanOffer";
import { ShippingBoxOffer } from "./ShippingBoxOffer";
import { BeanBagOffer } from "./BeanBagOffer";

export class Merchant {
  constructor(
    readonly id: string,
    public mvccId: string,
    public name: string,
    public street: string,
    public zip: string,
    public city: string,
    public phone: string,
    public country: Country,
    public beanOffers: BeanOffer[],
    public shippingBoxOffers: ShippingBoxOffer[],
    public beanBagOffers: BeanBagOffer[],
    public notes?: TranslatedValue,
    public shopLink?: string
  ) {}
}

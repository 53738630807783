import { Row, Col, Typography, Card } from "antd";
import QueueAnim from "rc-queue-anim";

import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { CoffeeCard } from "./CoffeeCard";
import { useStoredBeansBatches } from "./hooks/useStoredBeansBatches";
import { ImagePrefetcher } from "./ImagePrefetcher";
import { contentFull, landingSectionStyle } from "./layout";

export const Coffees = () => {
  const storedBeansBatches = useStoredBeansBatches();
  return (
    <div
      style={{
        ...landingSectionStyle,
        backgroundColor: "#f0f0f0",
      }}
    >
      <ImagePrefetcher />
      <Row justify="center">
        <Col>
          <Typography.Title level={2}>Unsere Kaffees</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col xs={24} style={{ marginTop: 50 }}>
          <OverPack playScale={0.15}>
            <QueueAnim type="bottom" key="coffee-row" leaveReverse>
              <Row justify="center" gutter={[64, 64]} key="coffee-row">
                {storedBeansBatches.data?.map((beanBatch) => (
                  <Col {...contentFull} key={`coffee-${beanBatch.id}}`}>
                    <CoffeeCard beanBatch={beanBatch} />
                  </Col>
                ))}
              </Row>
            </QueueAnim>
          </OverPack>
        </Col>
      </Row>
    </div>
  );
};

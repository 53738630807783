import { ShippingBox } from "./ShippingBox";

export class ShippingBoxOffer {
  constructor(
    readonly id: string,
    public shippingBox: ShippingBox,
    public pricePerUnitInEuro: number,
    public notes: string,
  ) {}
}

import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useMemo, useState } from "react";
import { CenteredLayout } from "../template/CenteredLayout";

export const Export = () => {
  const getExportData = () => {
    const keys = Object.keys(localStorage);
    let out: any = {};
    keys.forEach((key) => {
      const item = JSON.parse(localStorage.getItem(key)!);
      out[key] = item;
    });
    return out;
  };

  const [exportData, setExportData] = useState(getExportData());

  const [importString, setImportString] = useState("");

  const importValid = useMemo(() => {
    try {
      JSON.parse(importString);
      return true;
    } catch (error) {
      return false;
    }
  }, [importString]);

  const importData = () => {
    const data = JSON.parse(importString);
    console.log("import", data);
    const keys = Object.keys(data);
    keys.forEach((key) => {
      console.log("removing", key);
      localStorage.removeItem(key);
      console.log("writing", key);
      localStorage.setItem(key, JSON.stringify(data[key]));
    });
  };

  return (
    <>
      <CenteredLayout title="Export">
        <TextArea rows={8} value={JSON.stringify(exportData)} />
        <Button
          onClick={() => {
            setExportData(getExportData());
          }}
          type="primary"
        >
          Refresh
        </Button>
      </CenteredLayout>
      <CenteredLayout title="Import">
        <>
          <TextArea
            rows={8}
            value={importString}
            onChange={(e) => {
              setImportString(e.target.value);
            }}
          />
          <Button
            disabled={!importValid}
            onClick={() => {
              importData();
            }}
            type="primary"
          >
            Import
          </Button>
        </>
      </CenteredLayout>
    </>
  );
};

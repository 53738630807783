import { Row, Col, Space, Typography, Card } from "antd";
import QueueAnim from "rc-queue-anim";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { gutter, landingSectionStyle } from "./layout";
const footer = [
  {
    category: "FAQ",
    children: [{ title: "Bestellung", link: "#order" }],
  },
  {
    category: "Über Uns",
    children: [
      { title: "Wer wir sind", link: "#who-we-are" },
      { title: "Herausforderungen", link: "#challenges" },
      { title: "Unsere Lösungen", link: "#solutions" },
    ],
  },
  {
    category: "Rechtliches",
    children: [
      {
        title: "Impressum",
        link: "/imprint",
      },
      {
        title: "Datenschutz",
        link: "/privacy",
      },
    ],
  },
];

export const Footer = () => {
  return (
    <div
      style={{
        ...landingSectionStyle,
        paddingBottom: 0,
        minHeight: 0,
        backgroundColor: "#292929",
      }}
    >
      <Row style={{ marginBottom: 100 }}>
        <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 14, offset: 5 }} xs={24}>
          <OverPack playScale={0.1}>
            <QueueAnim
              type="bottom"
              key="block"
              leaveReverse
              component={Row as any}
              componentProps={{ gutter }}
            >
              <Col md={6} sm={12} xs={24} key={`col-img`} style={{marginBottom: 32}}>
                <Space direction="vertical">
                  <Card>
                    <img src="logo.jpeg" width="128px" alt="nice coffee" />
                  </Card>
                </Space>
              </Col>
              {footer.map((f, i) => {
                return (
                  <Col md={6} sm={12} xs={24} key={`col-${i}`} style={{marginBottom: 32}}>
                    <Space direction="vertical">  
                      <Typography.Title style={{ color: "white" }} level={2}>
                        {f.category}
                      </Typography.Title>
                      {f.children.map((c, i) => (
                        <Typography.Link href={c.link} key={`link-${i}`}>
                          {c.title}
                        </Typography.Link>
                      ))}
                    </Space>
                  </Col>
                );
              })}
            </QueueAnim>
          </OverPack>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 16, marginBottom: 16 }}>
        <Col>
          <Typography.Text style={{ color: "white" }}>
            Copyright Beangineers
          </Typography.Text>
        </Col>
      </Row>
    </div>
  );
};

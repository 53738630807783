import { TranslatedValue } from "./TranslatedValue";


export class ShippingMethod {
  constructor(
    readonly id: string,
    public mvccId: string,
    public name: TranslatedValue,
    public maxWeightInKg: number,
    public maxWidthInMM: number,
    public maxHeightInMM: number,
    public maxDepthInMM: number,
    public notes: string,
    public hasTracking: boolean,
    public insuranceSum: number,
    public shippingCompany: string,
    public price: number
  ) { }
}

import { BeanBag } from "./BeanBag";

export class BeanBagOffer {
  constructor(
    readonly id: string,
    public beanBag: BeanBag,
    public pricePerUnitInEuro: number,
    public note: string,
  ) {}
}

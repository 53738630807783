
import { CenteredLayout } from "../../template/CenteredLayout";
import { AftertasteLevelsList, Create } from "./AftertasteLevelsList";


export function AftertasteLevels() {
  return (
    <CenteredLayout title="Aftertaste Levels" extra={[<Create key="create" />]}>
      <AftertasteLevelsList />
    </CenteredLayout>
  );
}


import { CenteredLayout } from "../../template/CenteredLayout";
import { Create, ShippingBoxesList } from "./ShippingBoxesList";


export function ShippingBoxes() {
  return (
    <CenteredLayout title="Shipping Boxes" extra={[<Create key="create" />]}>
      <ShippingBoxesList />
    </CenteredLayout>
  );
}

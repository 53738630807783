import { TranslatedValue } from "./TranslatedValue";
/**
 * Arabica, Robusta, ... there are more, we just have to get to know them yet.
 */

export class BeanType {
  constructor(
    public readonly id: string,
    public mvccId: string,
    public name: TranslatedValue
  ) {}
}

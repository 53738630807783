import { PageHeader, Typography } from "antd";

const { Text, Title } = Typography;

export const PageTitle = (props: { title?: string; subtitle?: string, extra?: React.ReactNode[] }) => {
    return (
        <PageHeader
            className="site-page-header"
            title={
                <Title level={1} style={{ margin: 0, marginBottom: 4 }}>
                    {props.title}
                </Title>
            }
            subTitle={<Text type="secondary">{props.subtitle}</Text>}
            extra={props.extra}
        />
    );
};

import { InputNumber } from "antd";
import Form from "antd/lib/form";
import { formItemLayout } from "../formLayouts";

// TODO: validation rules!
export const WeightInput = (props: { path: string; label: string }) => {
  return (
    <>
      <Form.Item
        labelAlign="left"
        labelCol={formItemLayout.labelCol}
        rules={[
          {
            required: true,
          },
          {
            validator: async (rule, value: number, callback) => {
              if (value <= 0) {
                return Promise.reject("must be > 0");
              }
              return Promise.resolve();
            },
          },
        ]}
        label={`${props.label}`}
        required={true}
        name={[props.path]}
      >
        <InputNumber step={0.1} addonAfter="Kg" />
      </Form.Item>
    </>
  );
};

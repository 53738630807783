import { Space, Statistic } from "antd";
import { useParams } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Label,
} from "recharts";
import { CenteredLayout } from "tsx/pages/template/CenteredLayout";
import { PageLoading } from "tsx/pages/template/PageLoading";
import { useRoastworldRoastDetails } from "../hooks/useRoastworldRoastDetails";
import { useRoastworldRoasts } from "../hooks/useRoastworldRoasts";

export const RoastworldRoastDetails = () => {
  const { id } = useParams();
  const roasts = useRoastworldRoasts();
  const roast = (roasts.data || []).find((r) => r.id === id);
  const details = useRoastworldRoastDetails(roast);

  const isLoading = roasts.isLoading || details.isLoading;
  const isSuccess = roasts.isSuccess && details.isSuccess;

  const temp = details.data?.beanTemperature?.map((val, idx) => {
    return {
      x: idx,
      beanTemp: val,
      drumTemp: details.data?.drumTemperature[idx],
      beanDerivative: details.data?.beanDerivative[idx],
      ibtsDerivative: details.data?.ibtsDerivative[idx],
      exitTemperature: details.data?.exitTemperature[idx],
    };
  });

  function sec2time(timeInSeconds: number) {
    var pad = function (num: number, size: number) {
        return ("000" + num).slice(size * -1);
      },
      time = timeInSeconds,
      hours = Math.floor(time / 60 / 60),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60);
    return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
  }

  const chart = (
    <CenteredLayout title={details.data?.roastName}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space size="large">
          <Statistic
            title="Preheat"
            value={details.data?.preheatTemperature}
            suffix="°C"
            precision={0}
          />
          <Statistic
            title="Green"
            value={details.data?.weightGreen}
            suffix="g"
            precision={0}
          />
          <Statistic
            title="Roasted"
            value={details.data?.weightRoasted}
            suffix="g"
            precision={0}
          />
          <Statistic
            title="Loss"
            value={
              100 -
              (100 * (details.data?.weightRoasted || 1)) /
                (details.data?.weightGreen || 1)
            }
            suffix="%"
            precision={2}
          />
        </Space>
        <div style={{ width: "100%", height: 480 }}>
          <ResponsiveContainer>
            <LineChart
              data={temp}
              margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis
                dataKey="x"
                type="number"
                tickFormatter={sec2time}
                max={details.data?.roastEndIndex}
              />
              <YAxis yAxisId="left" domain={[() => 0, () => 400]} />
              <YAxis
                yAxisId="right"
                orientation="right"
                domain={[() => -250, () => 250]}
              />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
              <ReferenceLine
                yAxisId="left"
                x={details.data?.indexFirstCrackStart || -1}
                stroke="black"
                label={
                  <Label
                    value={`First Crack ${details.data?.firstCrackTemp}°C`}
                    offset={0}
                    position="top"
                  />
                }
              />
              <Tooltip />
              <Line
                type="monotone"
                yAxisId="left"
                name="Bean Temperature"
                dataKey="beanTemp"
                stroke="#191102"
                dot={false}
                strokeWidth={3}
              />
              <Line
                type="monotone"
                yAxisId="left"
                name="Drum Temperature"
                dataKey="drumTemp"
                stroke="#510d0a"
                dot={false}
                strokeWidth={3}
              />
              <Line
                type="monotone"
                yAxisId="right"
                name="Bean Derivative"
                dataKey="beanDerivative"
                stroke="#a29f15"
                dot={false}
              />
              <Line
                type="monotone"
                yAxisId="right"
                name="ibits Derivative"
                dataKey="ibtsDerivative"
                stroke="#f3b61f"
                dot={false}
              />
              {/* <Line
          type="monotone"
          yAxisId="left"
          name="Exit Temperature"
          dataKey="exitTemperature"
          stroke="#8884d8"
        /> */}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Space>
    </CenteredLayout>
  );

  return (
    <>
      {isLoading && <PageLoading />}
      {isSuccess && chart}
    </>
  );
};

import { getBackendHooks } from "tsx/pages/template/hooks/useAttachBackendHandler";
import { LocalStorageBeanMerchantsBackend } from "ts/backends/beanMercheants/LocalStorageBeanMerchantsBackend";
import { useMutation } from "react-query";
import { BeanOffer } from "ts/model/BeanOffer";
import { BeanBagOffer } from "ts/model/BeanBagOffer";
import { ShippingBoxOffer } from "ts/model/ShippingBoxOffer";

export const queryKey = ["merchants"];
const backend = new LocalStorageBeanMerchantsBackend();
export const beanMerchantsHooks = getBackendHooks(
  {
    add: backend.add.bind(backend),
    delete: backend.delete.bind(backend),
    fetch: backend.fetchAll.bind(backend),
    onAdd: backend.onAdded.bind(backend),
    onDelete: backend.onRemoved.bind(backend),
    onUpdate: backend.onUpdated.bind(backend),
    update: backend.update.bind(backend),
  },
  queryKey
);

export const useCreateBeanOffer = (merchantId: string) => {
  return useMutation<BeanOffer, any, BeanOffer>((val) => {
    // TODO: optimistic update
    return backend.addBeanOffer(merchantId, val);
  });
};
export const useUpdateBeanOffer = (merchantId: string) => {
  return useMutation<BeanOffer, any, BeanOffer>((val) => {
    // TODO: optimistic update
    return backend.updateBeanOffer(merchantId, val);
  });
};
export const useDeleteBeanOffer = (merchantId: string) => {
  return useMutation<BeanOffer, any, BeanOffer>((val) => {
    // TODO: optimistic update
    return backend.deleteBeanOffer(merchantId, val);
  });
};

export const useCreateBeanBagOffer = (merchantId: string) => {
  return useMutation<BeanBagOffer, any, BeanBagOffer>((val) => {
    // TODO: optimistic update
    return backend.addBeanBagOffer(merchantId, val);
  });
};
export const useUpdateBeanBagOffer = (merchantId: string) => {
  return useMutation<BeanBagOffer, any, BeanBagOffer>((val) => {
    // TODO: optimistic update
    return backend.updateBeanBagOffer(merchantId, val);
  });
};
export const useDeleteBeanBagOffer = (merchantId: string) => {
  return useMutation<BeanBagOffer, any, BeanBagOffer>((val) => {
    // TODO: optimistic update
    return backend.deleteBeanBagOffer(merchantId, val);
  });
};
export const useCreateShippingBoxOffer = (merchantId: string) => {
  return useMutation<ShippingBoxOffer, any, ShippingBoxOffer>((val) => {
    // TODO: optimistic update
    return backend.addShippingBoxOffer(merchantId, val);
  });
};
export const useUpdateShippingBoxOffer = (merchantId: string) => {
  return useMutation<ShippingBoxOffer, any, ShippingBoxOffer>((val) => {
    // TODO: optimistic update
    return backend.updateShippingBoxOffer(merchantId, val);
  });
};
export const useDeleteShippingBoxOffer = (merchantId: string) => {
  return useMutation<ShippingBoxOffer, any, ShippingBoxOffer>((val) => {
    // TODO: optimistic update
    return backend.deleteShippingBoxOffer(merchantId, val);
  });
};

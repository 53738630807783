import { Unsubscribe } from "../BeansBackend";
import { ProcessingTypesBackend } from "./ProcessingTypesBackend";
import { GenericLocalStorageBackend } from "../GenericLocalStorageBackend";
import { localStorageBackendBroker } from "../localStorageBackendBroker";
import { ProcessingType } from "ts/model/ProcessingType";

type BackendType = ProcessingType;
const storageKey = "processing-types";
const messageSuffix = "ProcessingType";
export class LocalStorageProcessingTypesBackend implements ProcessingTypesBackend {
  private readonly backend = new GenericLocalStorageBackend<BackendType>(
    storageKey,
    `add${messageSuffix}`,
    `update${messageSuffix}`,
    `delete${messageSuffix}`,
    localStorageBackendBroker
  );
  fetchAll(): Promise<BackendType[]> {
    return this.backend.fetchAll();
  }
  add(data: BackendType): Promise<BackendType> {
    return this.backend.addData(data);
  }
  update(data: BackendType): Promise<BackendType> {
    return this.backend.updateData(data);
  }
  delete(data: BackendType): Promise<BackendType> {
    return this.backend.deleteData(data);
  }
  onAdded(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onAdded(handler);
  }
  onUpdated(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onUpdated(handler);
  }
  onRemoved(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onRemoved(handler);
  }
}

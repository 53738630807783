import { Input, InputNumber } from "antd";
import Form from "antd/lib/form";
import { formItemLayout } from "../formLayouts";

// TODO: validation rules!
export const PriceInput = (props: { path: string; label: string }) => {
  return (
    <>
      <Form.Item
        labelAlign="left"
        labelCol={formItemLayout.labelCol}
        rules={[
          {
            required: true,
          },
        ]}
        label={`${props.label}`}
        required={true}
        name={[props.path]}
      >
        <InputNumber addonAfter="€" />
      </Form.Item>
    </>
  );
};

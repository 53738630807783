import { Table, Space } from "antd";
import { ConfirmationDeleteButton } from "tsx/pages/template/ConfirmationDeleteButton";
import { DefaultForm } from "tsx/pages/template/form/DefaultForm";
import { DefaultFormProps } from "tsx/pages/template/form/defaultFormProps";
import { UpsertModal } from "tsx/pages/template/form/UpsertModal";
import { WeightInput } from "tsx/pages/template/form/WeightInput";
import { StringInput } from "tsx/pages/template/form/StringInput";
import { BeanBag } from "ts/model/BeanBag";
import { beanBagsHooks } from "./BeanBagsHooks";

export const queryKey = ["beanBags"];
type usedType = BeanBag;
const initial = new BeanBag("", "", "", 0, "");
const usedTypeName = "Bean Bag";

const DataForm = (props: DefaultFormProps<usedType>) => {
  // TODO: offers!
  return (
    <DefaultForm {...props}>
      <StringInput path={"name"} label={"Name"} required={true} />
      <WeightInput label="Capacity" path="capacityInKg" />
      <StringInput path={"notes"} label={"Notes"} required={false} />
    </DefaultForm>
  );
};

export const Create = () => {
  const insert = beanBagsHooks.useInsert();
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={insert}
    />
  );
};

const Update = (props: { data: usedType }) => {
  const action = beanBagsHooks.useUpdate();
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const BeanBagsList = () => {
  const data = beanBagsHooks.useData();
  const deleteData = beanBagsHooks.useDelete();
  return (
    <Table
      loading={data.isLoading}
      dataSource={data.data}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Capacity",
          render: (val: usedType) => {
            return `${val.capacityInKg}Kg`;
          },
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

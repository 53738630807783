import { Table, Space } from "antd";
import { ConfirmationDeleteButton } from "tsx/pages/template/ConfirmationDeleteButton";
import { DefaultForm } from "tsx/pages/template/form/DefaultForm";
import { DefaultFormProps } from "tsx/pages/template/form/defaultFormProps";
import { UpsertModal } from "tsx/pages/template/form/UpsertModal";
import { ShippingBox } from "ts/model/ShippingBox";
import { shippingBoxesHooks } from "./shippingBoxesHooks";
import { WeightInput } from "tsx/pages/template/form/WeightInput";
import { StringInput } from "tsx/pages/template/form/StringInput";
import { DimensionInput } from "tsx/pages/template/form/DimensionInput";

export const queryKey = ["shippingBoxes"];
type usedType = ShippingBox;
const initial = new ShippingBox("", "", "", 0, 0, 0, 0, "");
const usedTypeName = "Shipping Box";

const DataForm = (props: DefaultFormProps<usedType>) => {
  // TODO: offers!
  return (
    <DefaultForm {...props}>
      <StringInput path={"name"} label={"Name"} required={true} />
      <WeightInput label="Capacity" path="capacityInKg" />
      <DimensionInput path={"widthInMM"} label={"Width"} />
      <DimensionInput path={"heightInMM"} label={"Height"} />
      <DimensionInput path={"depthInMM"} label={"Depth"} />
      <StringInput path={"notes"} label={"Notes"} required={false} />
    </DefaultForm>
  );
};

export const Create = () => {
  const insert = shippingBoxesHooks.useInsert();
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={insert}
    />
  );
};

const Update = (props: { data: usedType }) => {
  const action = shippingBoxesHooks.useUpdate();
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const ShippingBoxesList = () => {
  const data = shippingBoxesHooks.useData();
  const deleteData = shippingBoxesHooks.useDelete();
  return (
    <Table
      loading={data.isLoading}
      dataSource={data.data}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Capacity",
          render: (val: usedType) => {
            return `${val.capacityInKg}Kg`;
          },
        },
        {
          title: "Size",
          render: (val: usedType) => {
            return `${val.widthInMM}x${val.heightInMM}x${val.depthInMM}`;
          },
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

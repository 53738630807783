import { Select } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import { useState } from "react";
import { TranslatedValue } from "ts/model/TranslatedValue";
import { formItemLayout } from "../formLayouts";

export const ObjectSelectInput = <
  T extends { id: string; name: TranslatedValue | string }
>(props: {
  path: string[];
  label: string;
  source?: T[];
  loading: boolean;
  form: FormInstance;
  required?: boolean;
}) => {
  const [validationStatus, setValidationStatus] = useState<undefined | "error">(
    undefined
  );
  return (
    <>
      <Form.Item
        noStyle
        name={[...props.path]}
        rules={[
          {
            required: props.required,
            validator: async (rule, value: string, callback) => {
              if (!props.required) return Promise.resolve();
              let subField = props.form.getFieldsValue();
              props.path.forEach((subpath) => {
                subField = subField[subpath];
              });
              if (!subField || !subField.id) {
                setValidationStatus("error");
                return Promise.reject("error");
              }
              setValidationStatus(undefined);
              return Promise.resolve();
            },
          },
        ]}
      >
        <Form.Item
          labelAlign="left"
          labelCol={formItemLayout.labelCol}
          label={props.label}
          required={props.required}
          validateStatus={validationStatus}
          help={validationStatus === "error" ? "selection required" : ""}
          shouldUpdate={(prev, current) => {
            if (!prev || !current) return true;
            let subFieldPrev = prev;
            props.path.forEach((subpath) => {
              if (!subFieldPrev) return true;
              subFieldPrev = subFieldPrev[subpath];
            });
            let subFieldCurrent = current;
            props.path.forEach((subpath) => {
              if (!subFieldCurrent) return true;
              subFieldCurrent = subFieldCurrent[subpath];
            });
            return subFieldPrev !== subFieldCurrent;
          }}
        >
          {() => (
            <>
              <Select
                style={{ width: "100%" }}
                value={props.form.getFieldValue(props.path)?.id}
                loading={props.loading}
                onSelect={(installerId: string) => {
                  let subField = props.form.getFieldsValue();
                  props.path.forEach((subpath) => {
                    subField = subField[subpath];
                  });
                  const originalObject = props.source?.find(
                    (i) => i.id === installerId
                  );
                  if (originalObject) {
                    props.form.setFields([
                      {
                        name: props.path,
                        value: { ...originalObject },
                      },
                    ]);
                  }
                  props.form.validateFields([props.path]);
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {props.source?.map((option) => {
                  return (
                    <Select.Option key={option.id} value={option.id}>
                      {typeof option.name === "string"
                        ? option.name
                        : option.name.english}
                    </Select.Option>
                  );
                })}
              </Select>
            </>
          )}
        </Form.Item>
      </Form.Item>
    </>
  );
};

import EventEmitter from "eventemitter3";
import { v4 as uuidv4 } from "uuid";
import { Unsubscribe } from "./BeansBackend";

export class GenericLocalStorageBackend<
  T extends { id: string; mvccId: string }
> {
  constructor(
    private storageKey: string,
    private addMessage: string,
    private updateMessage: string,
    private deleteMessage: string,
    private broker: EventEmitter
  ) {}
  public getStorage(): T[] {
    const localStorageContent = localStorage.getItem(this.storageKey);
    if (localStorageContent) {
      return JSON.parse(localStorageContent) as T[];
    }
    return [];
  }
  private setStorage(types: T[]) {
    localStorage.setItem(this.storageKey, JSON.stringify(types));
  }

  public addData(data: T): Promise<T> {
    const copy = {
      ...JSON.parse(JSON.stringify(data)),
      id: uuidv4(),
      mvccId: "0",
    } as T;
    const storage = this.getStorage();
    this.setStorage([...storage, copy]);
    this.broker.emit(this.addMessage, copy);
    return Promise.resolve(copy);
  }

  public updateData(data: T): Promise<T> {
    const copy = {
      ...JSON.parse(JSON.stringify(data)),
      mvccId: (parseInt(data.mvccId) + 1).toString(),
    } as T;
    const stored = this.getStorage();
    const index = stored.findIndex((d) => d.id === copy.id);
    if (index < 0) {
      return Promise.reject("id not found");
    }
    if (stored[index].mvccId !== data.mvccId) {
      return Promise.reject("mvccid not equal");
    }
    stored.splice(index, 1);
    this.setStorage([...stored, copy]);
    console.log("emit", { message: this.updateMessage, data: copy });
    this.broker.emit(this.updateMessage, copy);
    return Promise.resolve(copy);
  }

  public deleteData(data: T): Promise<T> {
    const copy = { ...JSON.parse(JSON.stringify(data)) } as T;
    const stored = this.getStorage();
    const index = stored.findIndex((d) => d.id === data.id);
    if (index < 0) {
      return Promise.reject("id not found");
    }
    if (stored[index].mvccId !== data.mvccId) {
      return Promise.reject("mvccid not equal");
    }
    stored.splice(index, 1);
    this.setStorage([...stored]);
    this.broker.emit(this.deleteMessage, copy);
    return Promise.resolve(copy);
  }

  public onAdded(handler: (data: T) => void): Unsubscribe {
    const wrappedHandler = (val: T) => handler(val);
    this.broker.on(this.addMessage, wrappedHandler);
    return {
      unsubscribe: () => this.broker.off(this.addMessage, wrappedHandler),
    };
  }
  public onUpdated(handler: (data: T) => void): Unsubscribe {
    const wrappedHandler = (val: T) => handler(val);
    this.broker.on(this.updateMessage, wrappedHandler);
    return {
      unsubscribe: () => this.broker.off(this.updateMessage, wrappedHandler),
    };
  }
  public onRemoved(handler: (data: T) => void): Unsubscribe {
    const wrappedHandler = (val: T) => handler(val);
    this.broker.on(this.deleteMessage, wrappedHandler);
    return {
      unsubscribe: () => this.broker.off(this.deleteMessage, wrappedHandler),
    };
  }

  fetchAll(): Promise<T[]> {
    // return new Promise((resolve)=>{
    //   setTimeout(()=>{
    //     resolve(this.getStorage());
    //   }, Math.random() * 500)
    // })
    return Promise.resolve(this.getStorage());
  }
}

export class DateFactory {
    static nowInUtc(): any {
        return new Date()
    }

    static startOfDayInUtc(year: number, month: number, day: number) {
        //const craftedDate = new Date(`${year}-${month}-${day}T00:00:00+00:00`)
        const monthString = month < 10 ? `0${month}` : month
        const dayString = day < 10 ? `0${day}` : day
        const craftedDate = new Date(`${year}-${monthString}-${dayString}T00:00:00.000+00:00`)
        return craftedDate
    }

    static endOfDayInUtc(year: number, month: number, day: number) {
        const monthString = month < 10 ? `0${month}` : month
        const dayString = day < 10 ? `0${day}` : day
        const craftedDate = new Date(`${year}-${monthString}-${dayString}T23:59:59.999+00:00`)
        return craftedDate
    }
}
import { AcidityLevels } from "./acidityLevels/AcidityLevels";
import { AftertasteLevels } from "./aftertaste/AftertasteLevels";
import { BodyLevels } from "./bodyLevels/BodyLevels";
import { Flavors } from "./flavors/Flavors";
import { IntensityLevels } from "./intensityLevels/IntensityLevels";
import { SweetnessLevels } from "./sweetnessLevels/SweetnessLevels";

export const FlavorPage = () => (
  <>
    <AcidityLevels />
    <SweetnessLevels />
    <BodyLevels />
    <AftertasteLevels />
    <IntensityLevels />
    <Flavors />
  </>
);

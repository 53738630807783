import { Table, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { CenteredLayout } from "tsx/pages/template/CenteredLayout";
import {
  RoastworldRoast,
  useRoastworldRoasts,
} from "../hooks/useRoastworldRoasts";

export const RoastworldRoastsList = () => {
  const data = useRoastworldRoasts();
  const navigate = useNavigate();

  return (
    <CenteredLayout title="Roastworld Roasts">
      <Table
        loading={data.isLoading}
        dataSource={data.data}
        columns={[
          {
            title: "Name",
            render: (val: RoastworldRoast) => (
              <Typography.Link onClick={()=>{navigate(`${val.id}`)}}>
                {val.roastName}
              </Typography.Link>
            ),
          },
          {
            title: "Date",
            render: (val: RoastworldRoast) => val.dateTime.toString(),
          },
        ]}
        rowKey={(record: RoastworldRoast) => record.id}
      />
    </CenteredLayout>
  );
};

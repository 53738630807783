import React, { FunctionComponent } from 'react';

type Props = {
    header:string,
    elementId:string
}

export const Page: FunctionComponent<Props> = ({ header, elementId, children }) => {
    return (
        <div id={elementId} data-testid={elementId}>
            <h2>{header}</h2>
            {children}
        </div>
    )
}


import { CenteredLayout } from "../../template/CenteredLayout";
import { Create, QualityLevelsList } from "./QualityLevelsList";

export function QualityLevels() {
  return (
    <CenteredLayout title="Quality Levels" extra={[<Create key="create" />]}>
      <QualityLevelsList />
    </CenteredLayout>
  );
}

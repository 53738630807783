
import { CenteredLayout } from "../../template/CenteredLayout";
import { Create, ProcessingTypesList } from "./ProcessingTypesList";


export function ProcessingTypes() {
  return (
    <CenteredLayout title="Processing Types" extra={[<Create key="create" />]}>
      <ProcessingTypesList />
    </CenteredLayout>
  );
}


export class ShippingBox {
  constructor(
    readonly id: string,
    public mvccId: string,
    public name: string,
    public capacityInKg: number,
    public widthInMM: number,
    public heightInMM: number,
    public depthInMM: number,
    public notes: string,
  ) {}
}


import { CenteredLayout } from "../template/CenteredLayout";

export const Privacy = () => {
  return (
    <CenteredLayout title="Datenschutzerklärung">
      <p>
        Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden
        von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der
        Bereitstellung eines funktionsfähigen und nutzerfreundlichen
        Internetauftritts, inklusive seiner Inhalte und der dort angebotenen
        Leistungen, verarbeitet. Gemäß Art. 4 Ziffer 1. der Verordnung (EU)
        2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“
        genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe
        automatisierter Verfahren ausgeführter Vorgang oder jede solche
        Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das
        Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung,
        die Anpassung oder Veränderung, das Auslesen, das Abfragen, die
        Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine
        andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
        Einschränkung, das Löschen oder die Vernichtung. Mit der nachfolgenden
        Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang,
        Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener
        Daten, soweit wir entweder allein oder gemeinsam mit anderen über die
        Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir
        Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur
        Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit
        hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.
        Unsere Datenschutzerklärung ist wie folgt gegliedert: I. Informationen
        über uns als Verantwortliche
        <br />
        II. Rechte der Nutzer und Betroffenen
        <br />
        III. Informationen zur Datenverarbeitung
        <br />
      </p>
      <h2 id="i-informationen-über-uns-als-verantwortliche">
        I. Informationen über uns als Verantwortliche
      </h2>
      <p>
        Verantwortlicher Anbieter dieses Internetauftritts im
        datenschutzrechtlichen Sinne ist:
      </p>
      <p>
        Richard Hauswald
        <br />
        Altnaundorf 31
        <br />
        01445 Radebeul
        <br />
        Deutschland
        <br />
        <img src="/imprint/phoneAndEmail.png" alt="Kontakt" />
        <br />
      </p>
      <h2 id="ii-rechte-der-nutzer-und-betroffenen">
        II. Rechte der Nutzer und Betroffenen
      </h2>
      <p>
        Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung
        haben die Nutzer und Betroffenen das Recht
      </p>
      <ul>
        <li>
          auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf
          Auskunft über die verarbeiteten Daten, aufweitere Informationen über
          die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15
          DSGVO);
        </li>
        <li>
          auf Berichtigung oder Vervollständigung unrichtiger bzw.
          unvollständiger Daten (vgl. auch Art. 16 DSGVO);
        </li>
        <li>
          auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art.
          17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß
          Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der
          Verarbeitung nach Maßgabe von Art. 18 DSGVO;
        </li>
        <li>
          auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten
          und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche
          (vgl. auch Art. 20 DSGVO);
        </li>
        <li>
          auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht
          sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß
          gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch
          Art. 77 DSGVO).
        </li>
      </ul>
      <p>
        Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen
        gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede
        Berichtigung oder Löschung von Daten oder die Einschränkung der
        Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt,
        zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese
        Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand
        verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft
        über diese Empfänger. Ebenfalls haben die Nutzer und Betroffenen nach
        Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung
        der sie betreffenden Daten, sofern die Daten durch den Anbieter nach
        Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere
        ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der
        Direktwerbung statthaft.
      </p>
      <h2 id="iii-informationen-zur-datenverarbeitung">
        III. Informationen zur Datenverarbeitung
      </h2>
      <p>
        Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden
        gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der
        Löschung der Daten keine gesetzlichen Aufbewahrungspflichten
        entgegenstehen und nachfolgend keine anderslautenden Angaben zu
        einzelnen Verarbeitungsverfahren gemacht werden. Serverdaten Aus
        technischen Gründen, insbesondere zur Gewährleistung eines sicheren und
        stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an
        uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog.
        Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das
        Betriebssystem, die Website, von der aus Sie auf unseren
        Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres
        Internetauftritts, die Sie besuchen, Datum des jeweiligen Zugriffs,
        Auftreten technischer Fehler und Diagnoseberichte sowie das Land, von
        dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben. Diese so
        erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht
        gemeinsam mit anderen Daten von Ihnen.
      </p>
      <p>
        Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit.
        f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung,
        Stabilität, Funktionalität und Sicherheit unseres Internetauftritts. Die
        Daten werden spätestens nach 60 Tage wieder gelöscht, soweit keine
        weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind
        die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise
        von der Löschung ausgenommen.
      </p>
      <h2 id="amazon-cloudfront-cdn">Amazon CloudFront (CDN)</h2>
      <p>
        Auf unserer Webseite wird ein Webservice des Unternehmens Amazon Europe
        Core S.à r.l. , 5 Rue Plaetis, 2338 Luxemburg (nachfolgend: Amazon
        CloudFront (CDN)) nachgeladen. Wir nutzen diese Daten, um die volle
        Funktionalität unserer Webseite zu gewährleisten. In diesem Zusammenhang
        wird Ihr Browser ggf. personenbezogene Daten an Amazon CloudFront (CDN)
        übermitteln. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1
        lit. f DSGVO. Das berechtigte Interesse besteht in einer fehlerfreien
        Funktion der Internetseite. Die Löschung der Daten erfolgt, sobald der
        Zweck ihrer Erhebung erfüllt wurde. Weitere Informationen zum Handling
        der übertragenen Daten finden Sie in der Datenschutzerklärung von Amazon
        CloudFront (CDN):{" "}
        <a href="https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&amp;nodeId=201909010">
          https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&amp;nodeId=201909010
        </a>
        . Sie können die Erfassung sowie die Verarbeitung Ihrer Daten durch
        Amazon CloudFront (CDN) verhindern, indem Sie die Ausführung von
        Script-Code in Ihrem Browser deaktivieren oder einen Script-Blocker in
        Ihrem Browser installieren (diesen finden Sie z.B. unter{" "}
        <a href="http://www.noscript.net">www.noscript.net</a> oder{" "}
        <a href="http://www.ghostery.com">www.ghostery.com</a>).
      </p>
      <h2 id="kontaktanfragen--kontaktmöglichkeit">
        Kontaktanfragen / Kontaktmöglichkeit
      </h2>
      <p>
        Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten,
        werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer
        Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und
        Beantwortung Ihre Anfrage erforderlich - ohne deren Bereitstellung
        können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.
        Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.
      </p>
      <p>
        Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet
        worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten
        entgegenstehen, wie bspw. bei einer sich etwaig anschließenden
        Vertragsabwicklung.
      </p>
      <h2 id="quelle">Quelle</h2>
      <p>
        <a href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html">
          Muster-Datenschutzerklärung
        </a>{" "}
        der{" "}
        <a href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html">
          Anwaltskanzlei Weiß &amp; Partner
        </a>
      </p>
    </CenteredLayout>
  );
};

import { Banner } from "./Banner";
import { Values } from "./Values";
import { Coffees } from "./Coffees";
import { Story } from "./Story";
import { Shop } from "./Shop";
import { Footer } from "./Footer";

export const Landing = () => {
  return (
    <>
      <main>
        <Banner />
        <Values />
        <Coffees />
        <Story />
        <Shop />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

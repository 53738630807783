import React, { FunctionComponent } from "react";
import { MainNavigation } from "tsx/pages/template/MainNavigation";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Store } from "store/Store";
import { Home } from "tsx/pages/home/Home";
import { Orders } from "tsx/pages/orders/Orders";
import { Vendors } from "tsx/pages/vendors/Vendors";
import { enableMapSet } from "immer";

import "antd/dist/antd.min.css";
import "./TheApplication.css";
import {
  ActivityTimerContextProvider,
  RemainingIdleTime,
} from "./ActivityTimer";
import { Privacy } from "../privacy/Privacy";
import { Imprint } from "../imprint/Imprint";
import { BeangineersQueryClientProvider } from "./QueryClientProvider";
import { Landing } from "../landing/Landing";
import { HandlerAttachments } from "./HandlerAttachments";
import { FlavorPage } from "../flavor/FlavorPage";
import { BeansPage } from "../beans/BeansPage";
import { Export } from "../export/Export";
import { Shipping } from "../shipping/Shipping";
import { Roasts } from "../roasts/Roasts";
import { RoastworldRoastDetails } from "../roasts/components/RoastworldRoastDetails";
enableMapSet();

type Props = {};

export const TheApplication: FunctionComponent<Props> = ({ children }) => {
  const app = (
    <>
      <HandlerAttachments>
        <h1>Beangineers Administration</h1>
        <RemainingIdleTime></RemainingIdleTime>
        <MainNavigation />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/beans" element={<BeansPage />} />
          <Route path="/flavors" element={<FlavorPage />} />
          <Route path="/vendors" element={<Vendors />} />
          <Route path="/export" element={<Export />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/roasts/roastworld" element={<Roasts />}>
          </Route>
          <Route path="/roasts/roastworld/:id" element={<RoastworldRoastDetails />} />
        </Routes>
      </HandlerAttachments>
    </>
  );
  return (
    <React.StrictMode>
      <BeangineersQueryClientProvider>
        <ActivityTimerContextProvider>
          <Store>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="/*" element={app} />
              </Routes>
            </BrowserRouter>
          </Store>
        </ActivityTimerContextProvider>
      </BeangineersQueryClientProvider>
    </React.StrictMode>
  );
};

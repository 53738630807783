import { Link } from "react-router-dom";

export function MainNavigation() {
  return (
    <nav
      id="MainNavigation"
      style={{
        borderBottom: "solid 1px",
        paddingBottom: "1rem",
      }}
    >
      <Link data-testid="MainNavigationHome" to="/home">
        Home
      </Link>{" "}
      |{" "}
      <Link data-testid="MainNavigationOrders" to="/orders">
        Orders
      </Link>{" "}
      |{" "}
      <Link data-testid="MainNavigationBeans" to="/beans">
        Beans
      </Link>{" "}
      |{" "}
      <Link data-testid="MainNavigationVendors" to="/vendors">
        Vendors
      </Link>{" "}
      |{" "}
      <Link data-testid="MainNavigationFlavors" to="/flavors">
        Flavors
      </Link>{" "}
      |{" "}
      <Link data-testid="MainNavigationShipping" to="/shipping">
        Shipping
      </Link>{" "}
      |{" "}
      <Link data-testid="MainNavigationRoasts" to="/roasts/roastworld">
        Roasts
      </Link>{" "}
      |{" "}
      <Link data-testid="MainNavigationExport" to="/export">
        Export
      </Link>
    </nav>
  );
}

import { Row, Col, Typography } from "antd";
import QueueAnim from "rc-queue-anim";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { landingSectionStyle } from "./layout";

const entryLayout = { lg: 8, xs: 24 };

export const Story = () => {
  return (
    <div style={landingSectionStyle}>
      <Row>
        <Col xl={{ span: 20, offset: 2 }} xxl={{ span: 18, offset: 3 }} xs={24}>
          <OverPack playScale={0.2}>
            <QueueAnim
              type="bottom"
              key="block"
              leaveReverse
              component={Row as any}
              componentProps={{ gutter: [64, 64] }}
            >
              <Col {...entryLayout} key="whoweare" id='who-we-are'>
                <Typography.Title level={2} style={{ textAlign: "center" }}>
                  Wer wir sind
                </Typography.Title>
                <p>
                  Die beangineers sind ein Software- und ein Werkstoffingenieur,
                  die seit über einem Jahrzent auf der Suche nach dem perfekten
                  Espresso sind. Die Reise führte über Espressomaschinen und
                  Mühlen zu guten Bohnen.
                </p>
                <p>
                  Kaffee ist ein Frischeprodukt. Es hat sich gezeigt, dass
                  wirklich frische Bohnen gleichmäßiger und besser extrahiert
                  werden können. Ein gröberer Mahlgrad und weniger starkes
                  Tampern senken zudem die Wahrscheinlichkeit für Fehler bei der
                  Zubereitung. Das schmeckt man später in der Tasse. Die
                  Beschaffung frischer Bohnen war für uns jedoch nicht immer von
                  Erfolg geprägt und nach näherer Betrachtung des Problems haben
                  wir den Grund verstanden.
                </p>
              </Col>
              <Col {...entryLayout} key="challenges" id='challenges'>
                <Typography.Title level={2} style={{ textAlign: "center" }}>
                  Die Herausforderungen
                </Typography.Title>
                <p>
                  Die Rentabilität der Herstellung gerösteten Kaffees ist
                  hauptsächlich von zwei Faktoren abhängig:
                </p>
                <ol>
                  <li>Arbeitszeit</li>
                  <li>Rohkaffeepreis</li>
                </ol>
                <p>
                  Der Preis für Rohkaffee sinkt mit steigender Abnahmemenge. Je
                  mehr man kauft, desto besser - solange man nur genug fertig
                  gerösteten Kaffee absetzen kann. Man kann aber auch mehr auf
                  ein Mal kaufen, wenn man Rohkaffee länger lagert. Der Preis
                  für die Arbeitszeit sinkt mit sinkender Rostdauer sowie
                  steigender Röstmenge/Trommelgrösse. Auch hier gilt: Je kürzer
                  man möglichst viel Kaffee auf einmal röstet, desto rentabler.
                </p>
                <p>
                  Das führt dazu, dass eine rentable Rösterei viel Kaffee auf
                  einmal kauft und röstet, was natürlich zu Lagerbestand führt.
                  Das ist gut, wenn man schnell Kaffee braucht, aber schlecht
                  für den Geschmack: Auch Rohkaffee verliert mit zunehmendem
                  Alter an Aroma. Dazu kommt das gleiche Problem bei fertig
                  gerösteten Bohnen, nur da ist es noch einmal stärker
                  ausgeprägt.
                </p>
                <p>
                  Beim Studium verschiedener Quellen wurde auch klar, dass nicht
                  nur der Rohkaffee, sondern auch der eigentliche Röstprozess
                  einen erheblichen Einfluss auf den Gechmack hat. Und wer will
                  schon gern den Einheitsbrei, der bei niemandem die 
                  Geschmacksknospen der Abneigung kitzelt, aber auch keine
                  geschmacklichen Höhenpunkte generiert.
                </p>
              </Col>
              <Col {...entryLayout} key="solutions" id='solutions'>
                <Typography.Title level={2} style={{ textAlign: "center" }}>
                  Die Lösung
                </Typography.Title>
                <p>
                  Wir rösten Kaffee in Kleinstmgengen, immer frisch und nach den
                  individuellen Anforderungen unserer Kaffeetrinker.
                </p>
                <p>
                  Um die genannten Herausforderungen zu überwinden, setzen wir
                  auf ausgefeilte, eigenentwickelte Software und einen hohen
                  Grad an Automatisierung mit anschließender Datenanalyse.
                  Folgende Eckpfeiler stellen sicher, unseren Anspruch an
                  Qualität in die Tat umsetzen zu können:
                </p>
                <ul>
                  <li>
                    Kontrolle über Qualität sowie Alter des Rohkaffees, das
                    Röstdatum und auch der Röstparameter
                  </li>
                  <li>
                    Anschaffung eines kleinen Probenrösters mit ausgefeiltem
                    Monitoring und ausführlicher Datenanalyse
                  </li>
                  <li>
                    Entwicklung neuer Software und Maschinen zur Automatisierung
                    der manuellen Prozesse, um auch langfristig immer frischen
                    Kaffee nach Kundengeschmack anbieten zu können
                  </li>
                </ul>
              </Col>
            </QueueAnim>
          </OverPack>
        </Col>
      </Row>
    </div>
  );
};

import * as React from "react";
import { beanStrainsHooks } from "../beans/beanStrains/BeanStrainsList";
import { beanTypesHooks } from "../beans/beanTypes/BeanTypesList";
import { processingTypesHooks } from "../beans/processingTypes/ProcessingTypesList";
import { qualityLevelsHooks } from "../beans/qualityLevels/QualityLevelsList";
import { acidityLevelHooks } from "../flavor/acidityLevels/AcidityLevelsList";
import { aftertasteLevelHooks } from "../flavor/aftertaste/AftertasteLevelsList";
import { bodyLevelHooks } from "../flavor/bodyLevels/BodyLevelsList";
import { flavorHooks } from "../flavor/flavors/FlavorsList";
import { intensityLevelsHooks } from "../flavor/intensityLevels/IntensityLevelsList";
import { sweetnessLevelHooks } from "../flavor/sweetnessLevels/SweetnessLevelsList";
import { beanBagsHooks } from "../shipping/beanBags/BeanBagsHooks";
import { shippingBoxesHooks } from "../shipping/boxes/shippingBoxesHooks";
import { shippingMethodsHooks } from "../shipping/methods/shippingMethodsHooks";
import { countriesHooks } from "../vendors/countries/CountriesList";
import { beanFarmsHooks } from "../vendors/farms/BeanFarmsList";
import { beanMerchantsHooks } from "../vendors/merchants/beanMerchantsHooks";

export const HandlerAttachments = (props: { children: React.ReactNode }) => {
  beanTypesHooks.useAttach();
  acidityLevelHooks.useAttach();
  bodyLevelHooks.useAttach();
  sweetnessLevelHooks.useAttach();
  aftertasteLevelHooks.useAttach();
  flavorHooks.useAttach();
  countriesHooks.useAttach();
  beanFarmsHooks.useAttach();
  processingTypesHooks.useAttach();
  intensityLevelsHooks.useAttach();
  beanStrainsHooks.useAttach();
  qualityLevelsHooks.useAttach();
  beanMerchantsHooks.useAttach();
  shippingBoxesHooks.useAttach();
  shippingMethodsHooks.useAttach();
  beanBagsHooks.useAttach();
  return <>{props.children}</>;
};

import { Merchants } from "../vendors/merchants/Merchants";
import { BeanBags } from "./beanBags/BeanBags";
import { ShippingBoxes } from "./boxes/ShippingBoxes";
import { ShippingMethods } from "./methods/ShippingMethods";

export const Shipping = () => (
  <>
    <BeanBags />
    <ShippingBoxes />
    <ShippingMethods />
    <Merchants />
  </>
);

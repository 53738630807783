import { useQuery } from "react-query";
import {
  StoredBeanBatch,
  StoredBeanBatchBackend,
} from "ts/backends/StoredBeanBatchBackend";

export const useStoredBeansBatches = () => {
  return useQuery<StoredBeanBatch[]>(["storedBeansBatches"], () => {
    return new StoredBeanBatchBackend().fetchAll();
  });
};

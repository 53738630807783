import { FormInstance } from "antd";

type formErrorSource = {
    statusCode: string;
    message: string;
    reason: {
        propertyPath: string;
        message: string;
    }[];
};

const indexRegex = /\[\d+\]/gm;
const indexStringRegex = /[^0-9]/gm;
export const useFormErrorMarker = (form: FormInstance) => {
    return (err: formErrorSource) => {
        if (err.statusCode !== "400") {
            return;
        }
        const fieldData =
            err.reason?.map((r) => {
                return { name: r.propertyPath.split(".").filter(s => s !== "").map(s => {;
                    if (indexRegex.test(s)) {
                        return parseInt(s.replace(indexStringRegex, ""), 10);
                    }
                    return s;
                }), errors: [r.message]};
            }) || [];
        form.setFields(fieldData);
        const existingFields = fieldData.filter((field) => form.getFieldInstance(field.name));
        const notExistingFields = fieldData.filter((field) => !form.getFieldInstance(field.name));
        notExistingFields.forEach((field) => {
            // TODO: present error to user
        });

        if (existingFields.length > 0) {
            form.scrollToField(existingFields[0].name);
        }
    };
};

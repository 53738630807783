
import { CenteredLayout } from "../../template/CenteredLayout";
import { Create, ShippingMethodsList } from "./ShippingMethodsList";


export function ShippingMethods() {
  return (
    <CenteredLayout title="Shipping Methods" extra={[<Create key="create" />]}>
      <ShippingMethodsList />
    </CenteredLayout>
  );
}

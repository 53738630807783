
import { CenteredLayout } from "../../template/CenteredLayout";
import { BodyLevelsList, Create } from "./BodyLevelsList";


export function BodyLevels() {
  return (
    <CenteredLayout title="Body Levels" extra={[<Create key="create" />]}>
      <BodyLevelsList />
    </CenteredLayout>
  );
}

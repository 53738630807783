import { Table, Space, Divider } from "antd";
import { LocalStorageBeanStrainsBackend } from "ts/backends/beanStrain/LocalStorageBeanStrainsBackend";
import { BeanFarm } from "ts/model/BeanFarm";
import { BeanStrain } from "ts/model/BeanStrain";
import { BeanType } from "ts/model/BeanType";
import { ProcessingType } from "ts/model/ProcessingType";
import { TasteProfile } from "ts/model/TasteProfile";
import { acidityLevelHooks } from "tsx/pages/flavor/acidityLevels/AcidityLevelsList";
import { aftertasteLevelHooks } from "tsx/pages/flavor/aftertaste/AftertasteLevelsList";
import { bodyLevelHooks } from "tsx/pages/flavor/bodyLevels/BodyLevelsList";
import { flavorHooks } from "tsx/pages/flavor/flavors/FlavorsList";
import { intensityLevelsHooks } from "tsx/pages/flavor/intensityLevels/IntensityLevelsList";
import { sweetnessLevelHooks } from "tsx/pages/flavor/sweetnessLevels/SweetnessLevelsList";
import { ObjectMultiSelectInput } from "tsx/pages/template/form/ObjectMultiSelectInput";
import { ObjectSelectInput } from "tsx/pages/template/form/ObjectSelectInput";
import { StringInput } from "tsx/pages/template/form/StringInput";
import { MultiLanguageOutput } from "tsx/pages/template/list/MultiLanguageOutput";
import { beanFarmsHooks } from "tsx/pages/vendors/farms/BeanFarmsList";
import { ConfirmationDeleteButton } from "../../template/ConfirmationDeleteButton";
import { DefaultForm } from "../../template/form/DefaultForm";
import { DefaultFormProps } from "../../template/form/defaultFormProps";
import { TranslatedValueInput } from "../../template/form/TranslatedValueInput";
import { UpsertModal } from "../../template/form/UpsertModal";
import { getBackendHooks } from "../../template/hooks/useAttachBackendHandler";
import { beanTypesHooks } from "../beanTypes/BeanTypesList";
import { processingTypesHooks } from "../processingTypes/ProcessingTypesList";

const backend = new LocalStorageBeanStrainsBackend();
const queryKey = ["beanStrains"];
type usedType = BeanStrain;
const initial = new BeanStrain(
  "",
  "0",
  "",
  {} as BeanType,
  {} as ProcessingType,
  {} as BeanFarm,
  {flavors: []} as any as TasteProfile,
  { german: "", english: "" }
);
const usedTypeName = "Bean Strain";

export const beanStrainsHooks = getBackendHooks(
  {
    add: backend.add.bind(backend),
    delete: backend.delete.bind(backend),
    fetch: backend.fetchAll.bind(backend),
    onAdd: backend.onAdded.bind(backend),
    onDelete: backend.onRemoved.bind(backend),
    onUpdate: backend.onUpdated.bind(backend),
    update: backend.update.bind(backend),
  },
  queryKey
);

const DataForm = (props: DefaultFormProps<usedType>) => {
  const types = beanTypesHooks.useData();
  const processingTypes = processingTypesHooks.useData();
  const farms = beanFarmsHooks.useData();
  const acidityLevels = acidityLevelHooks.useData();
  const sweetnessLevels = sweetnessLevelHooks.useData();
  const bodyLevels = bodyLevelHooks.useData();
  const intensityLevels = intensityLevelsHooks.useData();
  const afterTasteLevels = aftertasteLevelHooks.useData();
  const flavors = flavorHooks.useData();

  return (
    <DefaultForm {...props}>
      <StringInput path="name" label="Name" required />
      <ObjectSelectInput
        form={props.form!}
        label="Bean Type"
        loading={types.isLoading}
        source={types.data}
        path={["type"]}
        required
      />
      <ObjectSelectInput
        form={props.form!}
        label="Processing Type"
        loading={processingTypes.isLoading}
        source={processingTypes.data}
        path={["processing"]}
        required
      />
      <ObjectSelectInput
        form={props.form!}
        label="Farm"
        loading={farms.isLoading}
        source={farms.data}
        path={["farm"]}
        required
      />
      <TranslatedValueInput path={["notes"]} label="Notes" required={false} />
      <Divider>Taste Profile</Divider>
      <TranslatedValueInput
        path={["tasteProfile", "description"]}
        label="Description"
        required={false}
      />
      <ObjectSelectInput
        form={props.form!}
        label="Acidity"
        loading={acidityLevels.isLoading}
        source={acidityLevels.data}
        path={["tasteProfile", "acidity"]}
        required
      />
      <ObjectSelectInput
        form={props.form!}
        label="Sweetness"
        loading={sweetnessLevels.isLoading}
        source={sweetnessLevels.data}
        path={["tasteProfile", "sweetness"]}
        required
      />
      <ObjectSelectInput
        form={props.form!}
        label="Body"
        loading={bodyLevels.isLoading}
        source={bodyLevels.data}
        path={["tasteProfile", "body"]}
        required
      />
      <ObjectSelectInput
        form={props.form!}
        label="Intensity"
        loading={intensityLevels.isLoading}
        source={intensityLevels.data}
        path={["tasteProfile", "intensity"]}
        required
      />
      <ObjectSelectInput
        form={props.form!}
        label="Aftertaste"
        loading={afterTasteLevels.isLoading}
        source={afterTasteLevels.data}
        path={["tasteProfile", "afterTaste"]}
        required
      />
      <ObjectMultiSelectInput
        form={props.form!}
        nameGenerator={(val) => val.name.english}
        label="Flavors"
        loading={flavors.isLoading}
        source={flavors.data}
        path={["tasteProfile", "flavors"]}
        required
      />
    </DefaultForm>
  );
};

export const Create = () => {
  const insert = beanStrainsHooks.useInsert();
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={insert}
    />
  );
};

const Update = (props: { data: usedType }) => {
  const action = beanStrainsHooks.useUpdate();
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const BeanStrainsList = () => {
  const data = beanStrainsHooks.useData();
  const deleteData = beanStrainsHooks.useDelete();
  return (
    <Table
      loading={data.isLoading}
      dataSource={data.data}
      columns={[
        {
          title: "Name",
          dataIndex: ["name"],
        },
        {
          title: "Type",
          render: (val: usedType) => (<MultiLanguageOutput val={val.type.name} />),
        },
        {
          title: "Processing",
          render: (val: usedType) => (<MultiLanguageOutput val={val.processing.name} />),
        },
        {
          title: "Origin",
          render: (val: usedType) => (<MultiLanguageOutput val={val.farm.country.name} />),
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

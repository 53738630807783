import { TranslatedValue } from "./TranslatedValue";
import { Country } from "./Country";

/*

General Info

Farm: Fazenda Mariano

Varietal: 100% Catuai

Processing: Pulped Natural

Altitude: 980 metres above sea level

Owner: Manoel Barbosa Junqueira

Town / City: Poços de Caldas

Region: São Domingos Mountain - South Minas Gerais
Cupping Notes

Overall: Crisp, sweet, vanilla, meyer lemon, milk chocolate
*/

export class BeanFarm {
  constructor(
    readonly id: string,
    public mvccId: string,
    public name: string,
    public city: string,
    public region: string,
    public country: Country,
    public notes?: TranslatedValue,
    public shopLink?: string
  ) { }
}

import { CenteredLayout } from "../../template/CenteredLayout";
import { BeanTypesList, CreateBeanType } from "./BeanTypesList";

export function BeanTypes() {
  return (
    <CenteredLayout title="Bean Types" extra={[<CreateBeanType />]}>
      <BeanTypesList />
    </CenteredLayout>
  );
}

import { Unsubscribe } from "../BeansBackend";
import { BeanTypesBackend } from "./BeanTypesBackend";
import { GenericLocalStorageBackend } from "../GenericLocalStorageBackend";
import { localStorageBackendBroker } from "../localStorageBackendBroker";
import { BeanType } from "ts/model/BeanType";

type BackendType = BeanType;
const storageKey = "bean-types";
const messageSuffix = "BeanType";
export class LocalStorageBeanTypesBackend implements BeanTypesBackend {
  private readonly backend = new GenericLocalStorageBackend<BackendType>(
    storageKey,
    `add${messageSuffix}`,
    `update${messageSuffix}`,
    `delete${messageSuffix}`,
    localStorageBackendBroker
  );
  fetchAll(): Promise<BackendType[]> {
    return this.backend.fetchAll();
  }
  add(data: BackendType): Promise<BackendType> {
    return this.backend.addData(data);
  }
  update(data: BackendType): Promise<BackendType> {
    return this.backend.updateData(data);
  }
  delete(data: BackendType): Promise<BackendType> {
    return this.backend.deleteData(data);
  }
  onAdded(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onAdded(handler);
  }
  onUpdated(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onUpdated(handler);
  }
  onRemoved(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onRemoved(handler);
  }
}


import { CenteredLayout } from "../../template/CenteredLayout";
import { Create, SweetnessLevelsList } from "./SweetnessLevelsList";


export function SweetnessLevels() {
  return (
    <CenteredLayout title="Sweetness Levels" extra={[<Create key="create" />]}>
      <SweetnessLevelsList />
    </CenteredLayout>
  );
}

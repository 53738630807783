import { useEffect } from "react";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { Unsubscribe } from "ts/backends/BeansBackend";

type eventHandler<T extends { id: string }> = (
  handler: (data: T) => void
) => Unsubscribe;
type crudHandler<T extends { id: string }> = (data: T) => Promise<T>;

const upsertHandler =
  <T extends { id: string }>(queryClient: QueryClient, queryKey: string[]) =>
  (data: T) => {
    const stateItems = [...(queryClient.getQueryData(queryKey) as T[])];
    const existing = stateItems.findIndex((u) => u.id === data.id);
    if (existing > -1) {
      stateItems[existing] = data;
    } else {
      stateItems.push(data);
    }
    queryClient.setQueryData<T[]>(queryKey, stateItems);
  };

const deleteHandler =
  <T extends { id: string }>(queryClient: QueryClient, queryKey: string[]) =>
  (data: T) => {
    const stateItems = [...(queryClient.getQueryData(queryKey) as T[])];
    const index = stateItems.findIndex((u) => u.id === data.id);
    if (index > -1) {
      stateItems.splice(index, 1);
      queryClient.setQueryData<T[]>(queryKey, stateItems);
    }
  };

export const useAttachBackendHandler = <T extends { id: string }>(
  backend: {
    onAdd: eventHandler<T>;
    onUpdate: eventHandler<T>;
    onDelete: eventHandler<T>;
  },
  queryKey: string[]
) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    const upsertHandle = upsertHandler(queryClient, queryKey);
    const deleteHandle = deleteHandler(queryClient, queryKey);
    const add = backend.onAdd(upsertHandle);
    const update = backend.onUpdate(upsertHandle);
    const del = backend.onDelete(deleteHandle);
    return () => {
      add.unsubscribe();
      update.unsubscribe();
      del.unsubscribe();
    };
  }, [queryClient]);
};

const clearHiddenProperties = <T>(val: T): T => {
    Object.keys(val).forEach(key => {
      if(key.startsWith("_")) {
        delete (val as any)[key]
      }
    })
    return val;
}

const useData = <T>(fetch: () => Promise<T[]>, queryKey: string[]) => {
  return useQuery<T[]>(queryKey, () => {
    return fetch();
  });
};

export const useInsert = <T>(add: (data: T) => Promise<T>) => {
  return useMutation<T, any, T>((bt) => {
    return add(clearHiddenProperties(bt));
  });
};

export const useUpdate = <T>(update: (data: T) => Promise<T>) => {
  return useMutation<T, any, T>((bt) => {
    return update(clearHiddenProperties(bt));
  });
};

export const useDelete = <T>(del: (data: T) => Promise<T>) => {
  return useMutation<T, any, T>((bt) => {
    return del(clearHiddenProperties(bt));
  });
};

export const getBackendHooks = <T extends { id: string }>(
  backend: {
    fetch: () => Promise<T[]>;
    add: crudHandler<T>;
    update: crudHandler<T>;
    delete: crudHandler<T>;
    onAdd: eventHandler<T>;
    onUpdate: eventHandler<T>;
    onDelete: eventHandler<T>;
  },
  queryKey: string[]
) => {
  return {
    useAttach: () => useAttachBackendHandler<T>(backend, queryKey),
    useData: () => useData<T>(backend.fetch, queryKey),
    useInsert: () => useInsert<T>(backend.add),
    useUpdate: () => useUpdate<T>(backend.update),
    useDelete: () => useDelete<T>(backend.delete),
  };
};

import { DatePicker } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import moment from "moment";
import { formItemLayout } from "../formLayouts";

// TODO: validation rules!
export const MonthInput = (props: {
  path: string[];
  label: string;
  form: FormInstance;
}) => {
  return (
    <>
      <Form.Item noStyle name={props.path}>
        <Form.Item
          labelAlign="left"
          labelCol={formItemLayout.labelCol}
          label={`${props.label}`}
          required={true}
          shouldUpdate={(prev, current) => {
            if (!prev || !current) return true;
            let subFieldPrev = prev;
            props.path.forEach((subpath) => {
              if (!subFieldPrev) return true;
              subFieldPrev = subFieldPrev[subpath];
            });
            let subFieldCurrent = current;
            props.path.forEach((subpath) => {
              if (!subFieldCurrent) return true;
              subFieldCurrent = subFieldCurrent[subpath];
            });
            return subFieldPrev !== subFieldCurrent;
          }}
        >
          {() => {
            const formValue = props.form.getFieldValue(props.path);
            const harvestDate = formValue ? moment(formValue) : undefined;
            return (
              <DatePicker
                value={harvestDate}
                onChange={(date) => {
                  props.form.setFields([
                    {
                      name: props.path,
                      value: date?.startOf('month').add(15, 'days').toDate(),
                    },
                  ]);
                }}
                allowClear={true}
                picker="month"
              />
            );
          }}
        </Form.Item>
      </Form.Item>
    </>
  );
};

import { Row, Col, Card, Typography } from "antd";
import QueueAnim from "rc-queue-anim";

import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { useIOS } from "./hooks/useIOS";
import { useMobile } from "./hooks/useMobile";
import { landingSectionStyle, wrapper1 } from "./layout";

export const Shop = () => {
  const isMobile = useMobile();
  const isIOS = useIOS();

  const simpleLayout = isMobile || isIOS;

  const defaultBackgroundStyle = {
    ...landingSectionStyle,
    backgroundImage: 'url("/beans.jpg")',
    backgroundSize: "cover",
    backgroundAttachment: simpleLayout ? undefined : "fixed",
    backgroundPosition: "center",
  };

  return (
    <div style={defaultBackgroundStyle}>
      <OverPack playScale={0.2}>
        <QueueAnim type="bottom" key="block" leaveReverse>
          <Row gutter={[64, 64]} key="interested" id="order">
            <Col {...wrapper1}>
              <Card>
                <Row justify="center">
                  <Col span={24}>
                    <Typography.Title level={2} style={{ textAlign: "center" }}>
                      Interessiert?
                    </Typography.Title>
                  </Col>
                  <Col span={24}>
                    <Typography.Paragraph>
                      Hier wird bald ein moderner Webshop für kunbdenspezifisch
                      gerösteten Kaffee entstehen. Bis dieser jedoch das Licht
                      der Welt erblickt und es aus der Alpha- in die Betaphase
                      geschafft hat, würden wir uns über Deine Kaffeebestellung
                      per Mail freuen!
                    </Typography.Paragraph>
                    <Typography.Link href="mailto:richard.hauswald@gmail.com">
                      richard.hauswald@gmail.com
                    </Typography.Link>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </QueueAnim>
      </OverPack>
    </div>
  );
};

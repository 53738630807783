import { Checkbox } from "antd";
import Form from "antd/lib/form";
import { formItemLayout } from "../formLayouts";

// TODO: validation rules!
export const BooleanInput = (props: { path: string; label: string }) => {
  return (
    <>
      <Form.Item
        labelAlign="left"
        valuePropName="checked"
        labelCol={formItemLayout.labelCol}
        rules={[
          {
            required: true,
          },
        ]}
        label={`${props.label}`}
        required={true}
        name={[props.path]}
      >
        <Checkbox />
      </Form.Item>
    </>
  );
};


import { CenteredLayout } from "../../template/CenteredLayout";
import { Create, FlavorsList } from "./FlavorsList";


export function Flavors() {
  return (
    <CenteredLayout title="Flavors" extra={[<Create key="create" />]}>
      <FlavorsList />
    </CenteredLayout>
  );
}

import { Table, Space, Typography } from "antd";
import { ConfirmationDeleteButton } from "tsx/pages/template/ConfirmationDeleteButton";
import { DefaultForm } from "tsx/pages/template/form/DefaultForm";
import { DefaultFormProps } from "tsx/pages/template/form/defaultFormProps";
import { StringInput } from "tsx/pages/template/form/StringInput";
import { ObjectSelectInput } from "tsx/pages/template/form/ObjectSelectInput";
import { TranslatedValueInput } from "tsx/pages/template/form/TranslatedValueInput";
import { UpsertModal } from "tsx/pages/template/form/UpsertModal";
import { countriesHooks } from "../countries/CountriesList";
import { Country } from "ts/model/Country";
import { Merchant } from "ts/model/Merchant";
import { MultiLanguageOutput } from "tsx/pages/template/list/MultiLanguageOutput";
import { beanMerchantsHooks } from "./beanMerchantsHooks";
import { BeanOffersList } from "./BeanOffersList";
import { BeanBagOffersList } from "./BeanBagOffersList";
import { ShippingBoxOffersList } from "./ShippingBoxOffersList";

type usedType = Merchant;
const initial = new Merchant(
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  {} as Country,
  [],
  [],
  [],
  { german: "", english: "" },
  ""
);
const usedTypeName = "Merchant";

const DataForm = (props: DefaultFormProps<usedType>) => {
  const countries = countriesHooks.useData();
  return (
    <DefaultForm {...props}>
      <StringInput label="Name" path="name" required />
      <StringInput label="Street" path="street" required />
      <StringInput label="Zip" path="zip" required />
      <StringInput label="City" path="city" required />
      <StringInput label="Phone" path="phone" required />
      <ObjectSelectInput
        form={props.form!}
        label="Country"
        loading={countries.isLoading}
        source={countries.data}
        path={["country"]}
        required
      />
      <StringInput label="Shop Link" path="shopLink" required={false} />
      <TranslatedValueInput path={["notes"]} label="Notes" required={false} />
    </DefaultForm>
  );
};

export const Create = () => {
  const insert = beanMerchantsHooks.useInsert();
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={insert}
    />
  );
};

const Update = (props: { data: usedType }) => {
  const action = beanMerchantsHooks.useUpdate();
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const BeanMerchantsList = () => {
  const data = beanMerchantsHooks.useData();
  const deleteData = beanMerchantsHooks.useDelete();
  return (
    <Table
      loading={data.isLoading}
      dataSource={data.data}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <BeanOffersList offers={record.beanOffers} merchantId={record.id} />
            <BeanBagOffersList
              offers={record.beanBagOffers}
              merchantId={record.id}
            />
            <ShippingBoxOffersList
              offers={record.shippingBoxOffers}
              merchantId={record.id}
            />
          </>
        ),
      }}
      columns={[
        {
          title: "Name",
          dataIndex: ["name"],
        },
        {
          title: "City",
          dataIndex: ["city"],
        },
        {
          title: "Country",
          render: (val: usedType) => (
            <MultiLanguageOutput val={val.country.name} />
          ),
        },
        {
          title: "Link",
          render: (val: usedType) => {
            if (val.shopLink) {
              return (
                <Typography.Link target="_blank" href={val.shopLink}>
                  Link
                </Typography.Link>
              );
            }
            return <>-</>;
          },
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

import { Table, Space } from "antd";
import { ConfirmationDeleteButton } from "tsx/pages/template/ConfirmationDeleteButton";
import { DefaultForm } from "tsx/pages/template/form/DefaultForm";
import { DefaultFormProps } from "tsx/pages/template/form/defaultFormProps";
import { UpsertModal } from "tsx/pages/template/form/UpsertModal";
import { WeightInput } from "tsx/pages/template/form/WeightInput";
import { StringInput } from "tsx/pages/template/form/StringInput";
import { DimensionInput } from "tsx/pages/template/form/DimensionInput";
import { ShippingMethod } from "ts/model/ShippingMethod";
import { shippingMethodsHooks } from "./shippingMethodsHooks";
import { TranslatedValueInput } from "tsx/pages/template/form/TranslatedValueInput";
import { PriceInput } from "tsx/pages/template/form/PriceInput";
import { BooleanInput } from "tsx/pages/template/form/BooleanInput";
import { MultiLanguageOutput } from "tsx/pages/template/list/MultiLanguageOutput";

export const queryKey = ["shippingMethods"];
type usedType = ShippingMethod;
const initial = new ShippingMethod("", "", {german: '', english: ''}, 0, 0, 0, 0, '', false, 0, '', 0);
const usedTypeName = "Shipping Method";

const DataForm = (props: DefaultFormProps<usedType>) => {
  return (
    <DefaultForm {...props}>
      <TranslatedValueInput path={["name"]} label={"Name"} required={true} />
      <WeightInput label="Max Weight" path="maxWeightInKg" />
      <DimensionInput path={"maxWidthInMM"} label={"Max. Width"} />
      <DimensionInput path={"maxHeightInMM"} label={"Max. Height"} />
      <DimensionInput path={"maxDepthInMM"} label={"Max. Depth"} />
      <StringInput path={"shippingCompany"} label={"Shipping Company"} required={true} />
      <PriceInput path={"price"} label={"Price"} />
      <PriceInput path={"insuranceSum"} label={"Insurance Sum"} />
      <BooleanInput path={"hasTracking"} label={"Has Tracking"} />
      <StringInput path={"notes"} label={"Notes"} required={false} />
    </DefaultForm>
  );
};

export const Create = () => {
  const insert = shippingMethodsHooks.useInsert();
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={insert}
    />
  );
};

const Update = (props: { data: usedType }) => {
  const action = shippingMethodsHooks.useUpdate();
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const ShippingMethodsList = () => {
  const data = shippingMethodsHooks.useData();
  const deleteData = shippingMethodsHooks.useDelete();
  return (
    <Table
      loading={data.isLoading}
      dataSource={data.data}
      columns={[
        {
          title: "Name",
          render: (val: usedType) => (<MultiLanguageOutput val={val.name} />),
        },
        {
          title: "Company",
          dataIndex: ["shippingCompany"],
        },
        {
          title: "Constraints",
          render: (val: usedType) => {
            return `${val.maxWeightInKg}Kg, ${val.maxWidthInMM}x${val.maxHeightInMM}x${val.maxDepthInMM}`;
          },
        },
        {
          title: "Tracking",
          render: (val: usedType) => {
            return `${val.hasTracking ? 'true' : 'false'}`;
          },
        },
        {
          title: "Insurance",
          render: (val: usedType) => {
            return `${val.insuranceSum}€`;
          },
        },
        {
          title: "Price",
          render: (val: usedType) => {
            return `${val.price}€`;
          },
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

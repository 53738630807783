import { Input, InputNumber } from "antd";
import Form from "antd/lib/form";
import { formItemLayout } from "../formLayouts";

// TODO: validation rules!
export const StringInput = (props: {
  path: string;
  label: string;
  required: boolean;
}) => {
  return (
    <>
      <Form.Item
        labelAlign="left"
        labelCol={formItemLayout.labelCol}
        rules={[
          {
            required: props.required,
            min: 1,
          },
        ]}
        label={`${props.label}`}
        required={props.required}
        name={[props.path]}
      >
        <Input />
      </Form.Item>
    </>
  );
};

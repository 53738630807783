import {
  Unsubscribe,
} from "../BeansBackend";
import { BeanStrainsBackend } from "./BeanStrainsBackend";
import { GenericLocalStorageBackend } from "../GenericLocalStorageBackend";
import { localStorageBackendBroker } from "../localStorageBackendBroker";
import { AcidityLevel } from "ts/model/AcidityLevel";
import { Aftertaste } from "ts/model/Aftertaste";
import { BeanFarm } from "ts/model/BeanFarm";
import { BeanStrain } from "ts/model/BeanStrain";
import { BeanType } from "ts/model/BeanType";
import { BodyLevel } from "ts/model/BodyLevel";
import { Flavor } from "ts/model/Flavor";
import { IntensityLevel } from "ts/model/IntensityLevel";
import { ProcessingType } from "ts/model/ProcessingType";
import { SweetnessLevel } from "ts/model/SweetnessLevel";

type BackendType = BeanStrain;
const storageKey = "beanstrains";
const messageSuffix = "BeanStrain";

export class LocalStorageBeanStrainsBackend implements BeanStrainsBackend {
  private readonly backend = new GenericLocalStorageBackend<BackendType>(
    storageKey,
    `add${messageSuffix}`,
    `update${messageSuffix}`,
    `delete${messageSuffix}`,
    localStorageBackendBroker
  );

    constructor(){
      localStorageBackendBroker.on("updateBeanType", async (data: BeanType) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          if (s.type.id === data.id) {
            s.type = data;
            this.update(s);
          }
        });
      });

      localStorageBackendBroker.on("updateProcessingType", async (data: ProcessingType) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          if (s.processing.id === data.id) {
            s.processing = data;
            this.update(s);
          }
        });
      });

      localStorageBackendBroker.on("updateBeanFarm", async (data: BeanFarm) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          if (s.farm.id === data.id) {
            s.farm = data;
            this.update(s);
          }
        });
      });

      localStorageBackendBroker.on("updateAcidityLevel", async (data: AcidityLevel) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          if (s.tasteProfile.acidity.id === data.id) {
            s.tasteProfile.acidity = data;
            this.update(s);
          }
        });
      });
      localStorageBackendBroker.on("updateSweetnessLevel", async (data: SweetnessLevel) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          if (s.tasteProfile.sweetness.id === data.id) {
            s.tasteProfile.sweetness = data;
            this.update(s);
          }
        });
      });
      localStorageBackendBroker.on("updateBodyLevel", async (data: BodyLevel) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          if (s.tasteProfile.body.id === data.id) {
            s.tasteProfile.body = data;
            this.update(s);
          }
        });
      });
      localStorageBackendBroker.on("updateIntensityLevel", async (data: IntensityLevel) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          if (s.tasteProfile.intensity.id === data.id) {
            s.tasteProfile.intensity = data;
            this.update(s);
          }
        });
      });
      localStorageBackendBroker.on("updateAftertasteLevel", async (data: Aftertaste) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          if (s.tasteProfile.afterTaste.id === data.id) {
            s.tasteProfile.afterTaste = data;
            this.update(s);
          }
        });
      });
      localStorageBackendBroker.on("updateFlavor", async (data: Flavor) => {
        const store = await this.fetchAll();
        store.forEach((s) => {
          s.tasteProfile.flavors.forEach((f, idx) => {
            if(f.id === data.id) {
              s.tasteProfile.flavors[idx] = data;
              this.update(s);
            }
          })
        });
      });
    }

  fetchAll(): Promise<BackendType[]> {
    return this.backend.fetchAll();
  }
  add(data: BackendType): Promise<BackendType> {
    return this.backend.addData(data);
  }
  update(data: BackendType): Promise<BackendType> {
    return this.backend.updateData(data);
  }
  delete(data: BackendType): Promise<BackendType> {
    return this.backend.deleteData(data);
  }
  onAdded(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onAdded(handler);
  }
  onUpdated(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onUpdated(handler);
  }
  onRemoved(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onRemoved(handler);
  }
}

import {
  Unsubscribe,
} from "../BeansBackend";
import { BeanFarmsBackend } from "./BeanFarmsBackend";
import { GenericLocalStorageBackend } from "../GenericLocalStorageBackend";
import { localStorageBackendBroker } from "../localStorageBackendBroker";
import { BeanFarm } from "ts/model/BeanFarm";
import { Country } from "ts/model/Country";

type BackendType = BeanFarm;
const storageKey = "bean-farms";
const messageSuffix = "BeanFarm";
export class LocalStorageBeanFarmsBackend implements BeanFarmsBackend {
  private readonly backend = new GenericLocalStorageBackend<BackendType>(
    storageKey,
    `add${messageSuffix}`,
    `update${messageSuffix}`,
    `delete${messageSuffix}`,
    localStorageBackendBroker
  );

  constructor() {
    localStorageBackendBroker.on("updateCountry", async (country: Country) => {
      const farms = await this.fetchAll();
      farms.forEach((farm) => {
        if (farm.country.id === country.id) {
          farm.country = country;
          this.update(farm);
        }
      });
    });
  }

  fetchAll(): Promise<BackendType[]> {
    return this.backend.fetchAll();
  }
  add(data: BackendType): Promise<BackendType> {
    return this.backend.addData(data);
  }
  update(data: BackendType): Promise<BackendType> {
    return this.backend.updateData(data);
  }
  delete(data: BackendType): Promise<BackendType> {
    return this.backend.deleteData(data);
  }
  onAdded(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onAdded(handler);
  }
  onUpdated(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onUpdated(handler);
  }
  onRemoved(handler: (data: BackendType) => void): Unsubscribe {
    return this.backend.onRemoved(handler);
  }
}

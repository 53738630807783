import {
  Badge,
  Card,
  Col,
  Collapse,
  Divider,
  Image,
  Modal,
  Progress,
  Row,
  Typography,
} from "antd";
import Meta from "antd/lib/card/Meta";
import { useState } from "react";
import { StoredBeanBatch } from "ts/backends/StoredBeanBatchBackend";
import { BeanStrain } from "ts/model/BeanStrain";
import { TranslatedValue } from "ts/model/TranslatedValue";
import { TasteProfile } from "ts/model/TasteProfile";

export const CoffeeCard = (props: { beanBatch: StoredBeanBatch }) => {
  const [visible, setVisible] = useState(false);

  const labelColSpan = 8;
  const valueColSpan = 24 - labelColSpan;
  const TasteDetail = (props: {
    label?: string;
    value: { name: TranslatedValue; ordinal: number };
  }) => {
    return (
      <>
        <Col span={labelColSpan}>{props.label}</Col>
        <Col span={props.label ? valueColSpan : 24}>
          <Progress
            percent={props.value.ordinal}
            showInfo={false}
            size="small"
            status="normal"
          />
          <Typography.Text>{props.value.name.german}</Typography.Text>
        </Col>
      </>
    );
  };

  const TasteDetailString = (props: { label?: string; value: string }) => {
    return (
      <>
        <Col span={labelColSpan}>{props.label}</Col>
        <Col span={props.label ? valueColSpan : 24}>
          <Typography.Text>{props.value}</Typography.Text>
        </Col>
      </>
    );
  };

  const Type = (props: { beanStrain: BeanStrain }) => (
    // {`$, ${props.beanBatch.beanStrain.farm.region}, ${props.beanBatch.beanStrain.farm.city}`}
    <Row gutter={[0, 8]}>
      <TasteDetailString
        label="Typ"
        value={props.beanStrain.type.name.german}
      />
      <TasteDetailString
        label="Aufbereitung"
        value={props.beanStrain.processing.name.german}
      />
    </Row>
  );
  const Origin = (props: { beanStrain: BeanStrain }) => (
    // {`$, ${props.beanBatch.beanStrain.farm.region}, ${props.beanBatch.beanStrain.farm.city}`}
    <Row gutter={[0, 8]}>
      <TasteDetailString label="Farm" value={props.beanStrain.farm.name} />
      <TasteDetailString
        label="Land"
        value={props.beanStrain.farm.country.name.german}
      />
      <TasteDetailString label="Region" value={props.beanStrain.farm.region} />
      <TasteDetailString label="Stadt" value={props.beanStrain.farm.city} />
    </Row>
  );

  const Quality = (props: { beanBatch: StoredBeanBatch }) => (
    <Row gutter={[0, 8]}>
      <TasteDetail label="Niveau" value={props.beanBatch.quality} />
      <TasteDetailString
        label="Erntejahr"
        value={new Date(props.beanBatch.harvestDate.toString())
          .getFullYear()
          .toString()}
      />
    </Row>
  );

  const TasteProfile = (props: { tasteProfile: TasteProfile }) => {
    return (
      <Row gutter={[0, 8]}>
        <TasteDetail label="Süße" value={props.tasteProfile.sweetness} />
        <TasteDetail label="Säure" value={props.tasteProfile.acidity} />
        <TasteDetail label="Körper" value={props.tasteProfile.body} />
        <TasteDetail label="Intensität" value={props.tasteProfile.intensity} />
        <TasteDetail label="Abgang" value={props.tasteProfile.afterTaste} />
        <Col span={labelColSpan}>Aromen</Col>
        <Col span={valueColSpan}>
          <Row gutter={[4, 4]}>
            {props.tasteProfile.flavors.map((f) => (
              <Col>
                <Badge
                  style={{ backgroundColor: "#1890ff" }}
                  count={f.name.german}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <TasteDetailString
          label="Beschreibung"
          value={props.tasteProfile.description.german}
        />
      </Row>
    );
  };

  return (
    <>
      <Modal
        title={props.beanBatch.beanStrain.name}
        footer={null}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Image
              alt="rohkaffee"
              preview={false}
              src={props.beanBatch.imageLink}
            ></Image>
          </Col>
          <Divider>Eigenschaften</Divider>
          <Col span={24}>
            <Collapse>
              <Collapse.Panel
                header={`Typ: ${props.beanBatch.beanStrain.type.name.german}`}
                key="type"
              >
                <Type beanStrain={props.beanBatch.beanStrain} />
              </Collapse.Panel>
              <Collapse.Panel
                header={`Herkunft: ${props.beanBatch.beanStrain.farm.country.name.german}`}
                key="origin"
              >
                <Origin beanStrain={props.beanBatch.beanStrain} />
              </Collapse.Panel>
              <Collapse.Panel
                header={`Geschmack: ${props.beanBatch.beanStrain.tasteProfile.description.german}`}
                key="taste"
              >
                <TasteProfile
                  tasteProfile={props.beanBatch.beanStrain.tasteProfile}
                />
              </Collapse.Panel>
              <Collapse.Panel
                header={`Qualität: ${props.beanBatch.quality.name.german}`}
                key="quality"
              >
                <Quality beanBatch={props.beanBatch} />
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Modal>
      <Badge.Ribbon
        text={`Bestand: ${
          Math.round(props.beanBatch.amountInKg * 100) / 100
        }kg`}
      >
        <button
          onClick={() => {
            setVisible(true);
          }}
          style={{
            background: "transparent",
            border: "none",
            fontSize: 0,
            width: "100%" 
          }}
        >
          <Card
            hoverable
            onClick={() => {
              setVisible(true);
            }}
            style={{ width: "100%", cursor:"pointer" }}
            cover={
              <Image
                // use empty alt attribute because it is just decorative and screenreader output would be long
                alt=""
                preview={false}
                src={props.beanBatch.imageLink}
                placeholder={true}
              />
            }
          >
            <Meta
              title={props.beanBatch.beanStrain.name}
              description={`${props.beanBatch.beanStrain.type.name.german} / ${props.beanBatch.beanStrain.farm.country.name.german}`}
            />
          </Card>
        </button>
      </Badge.Ribbon>
    </>
  );
};

import { Table, Space, Typography } from "antd";
import { ConfirmationDeleteButton } from "tsx/pages/template/ConfirmationDeleteButton";
import { DefaultForm } from "tsx/pages/template/form/DefaultForm";
import { DefaultFormProps } from "tsx/pages/template/form/defaultFormProps";
import { ObjectSelectInput } from "tsx/pages/template/form/ObjectSelectInput";
import { UpsertModal } from "tsx/pages/template/form/UpsertModal";
import { PriceInput } from "tsx/pages/template/form/PriceInput";
import { StringInput } from "tsx/pages/template/form/StringInput";
import {
  useCreateShippingBoxOffer,
  useDeleteShippingBoxOffer,
  useUpdateShippingBoxOffer,
} from "./beanMerchantsHooks";
import { ShippingBoxOffer } from "ts/model/ShippingBoxOffer";
import { ShippingBox } from "ts/model/ShippingBox";
import { shippingBoxesHooks } from "tsx/pages/shipping/boxes/shippingBoxesHooks";

type usedType = ShippingBoxOffer;
const initial = new ShippingBoxOffer("", {} as ShippingBox, 0.0, "");
const usedTypeName = "Shipping Box Offer";

const DataForm = (props: DefaultFormProps<usedType>) => {
  const boxes = shippingBoxesHooks.useData();
  // TODO: offers!
  return (
    <DefaultForm {...props}>
      <ObjectSelectInput
        form={props.form!}
        label="Shipping Box"
        loading={boxes.isLoading}
        source={boxes.data}
        path={["shippingBox"]}
        required
      />
      <PriceInput label="Price/Unit" path="pricePerUnitInEuro" />
      <StringInput path={"notes"} label={"Notes"} required={false} />
    </DefaultForm>
  );
};

export const Create = (props: { merchantId: string }) => {
  const action = useCreateShippingBoxOffer(props.merchantId);
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={action}
    />
  );
};

const Update = (props: { data: usedType; merchantId: string }) => {
  const action = useUpdateShippingBoxOffer(props.merchantId);
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const ShippingBoxOffersList = (props: {
  offers: usedType[];
  merchantId: string;
}) => {
  const deleteData = useDeleteShippingBoxOffer(props.merchantId);
  return (
    <Table
      title={() => (
        <Typography.Title level={3}>Shipping Box Offers</Typography.Title>
      )}
      footer={() => <Create key="add" merchantId={props.merchantId} />}
      dataSource={props.offers}
      columns={[
        {
          title: "Shipping Box",
          dataIndex: ["shippingBox", "name"],
        },
        {
          title: "Price",
          render: (val: usedType) => `${val.pricePerUnitInEuro}€/Unit`,
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} merchantId={props.merchantId} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

import { Table, Space } from "antd";
import { LocalStorageBeanFarmsBackend } from "ts/backends/beanFarms/LocalStorageBeanFarmsBackend";
import { ConfirmationDeleteButton } from "tsx/pages/template/ConfirmationDeleteButton";
import { DefaultForm } from "tsx/pages/template/form/DefaultForm";
import { DefaultFormProps } from "tsx/pages/template/form/defaultFormProps";
import { StringInput } from "tsx/pages/template/form/StringInput";
import { ObjectSelectInput } from "tsx/pages/template/form/ObjectSelectInput";
import { TranslatedValueInput } from "tsx/pages/template/form/TranslatedValueInput";
import { UpsertModal } from "tsx/pages/template/form/UpsertModal";
import { getBackendHooks } from "tsx/pages/template/hooks/useAttachBackendHandler";
import { countriesHooks } from "../countries/CountriesList";
import { BeanFarm } from "ts/model/BeanFarm";
import { Country } from "ts/model/Country";

const backend = new LocalStorageBeanFarmsBackend();
const queryKey = ["beanFarms"];
type usedType = BeanFarm;
const initial = new BeanFarm("", "", "", "", "", {} as Country);
const usedTypeName = "Bean Farm";

export const beanFarmsHooks = getBackendHooks(
  {
    add: backend.add.bind(backend),
    delete: backend.delete.bind(backend),
    fetch: backend.fetchAll.bind(backend),
    onAdd: backend.onAdded.bind(backend),
    onDelete: backend.onRemoved.bind(backend),
    onUpdate: backend.onUpdated.bind(backend),
    update: backend.update.bind(backend),
  },
  queryKey
);

const DataForm = (props: DefaultFormProps<usedType>) => {
  const countries = countriesHooks.useData();
  return (
    <DefaultForm {...props}>
      <StringInput label="Name" path="name" required />
      <StringInput label="City" path="city" required />
      <StringInput label="Region" path="region" required />
      <ObjectSelectInput
        form={props.form!}
        label="Country"
        loading={countries.isLoading}
        source={countries.data}
        path={["country"]}
        required
      />
      <StringInput label="Shop Link" path="shopLink" required={false} />
      <TranslatedValueInput path={["notes"]} label="Notes" required={false} />
    </DefaultForm>
  );
};

export const Create = () => {
  const insert = beanFarmsHooks.useInsert();
  return (
    <UpsertModal
      title={`Create ${usedTypeName}`}
      mode="insert"
      initial={initial}
      form={DataForm}
      upsertAction={insert}
    />
  );
};

const Update = (props: { data: usedType }) => {
  const action = beanFarmsHooks.useUpdate();
  return (
    <UpsertModal
      title={`Update ${usedTypeName}`}
      hideButtonText
      mode="update"
      initial={props.data}
      form={DataForm}
      upsertAction={action}
    />
  );
};

export const BeanFarmsList = () => {
  const data = beanFarmsHooks.useData();
  const deleteData = beanFarmsHooks.useDelete();
  return (
    <Table
      loading={data.isLoading}
      dataSource={data.data}
      columns={[
        {
          title: "Name",
          dataIndex: ["name"],
        },
        {
          title: "City",
          dataIndex: ["city"],
        },
        {
          title: "Region",
          dataIndex: ["region"],
        },
        {
          title: "Country",
          dataIndex: ["country", "name", "english"],
        },
        {
          title: "Action",
          key: "action",
          width: 64,
          render: (data: usedType) => (
            <Space>
              <Update data={data} />
              <ConfirmationDeleteButton onOk={() => deleteData.mutate(data)} />
            </Space>
          ),
        },
      ]}
      rowKey={(record: usedType) => record.id}
    />
  );
};

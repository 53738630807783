import { TranslatedValue } from "./TranslatedValue";


export class Country {
  constructor(
    public readonly id: string,
    public mvccId: string,
    public name: TranslatedValue
  ) { }
}

import { Space } from "antd";
import { TranslatedValue } from "ts/model/TranslatedValue";

export const MultiLanguageOutput = (props: { val: TranslatedValue }) => {
  return (
    <>
      <Space direction="vertical" size="small">
        <span>{`🇩🇪 ${props.val.german}`}</span>
        <span>{`🇺🇸 ${props.val.english}`}</span>
      </Space>
    </>
  );
};

// import { Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";

export type ConfirmationDeleteButtonProps = {
  onOk: () => any;
  caption?: string;
  description?: string;
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  primaryType?: boolean;
};

export const ConfirmationDeleteButton = (
  props: ConfirmationDeleteButtonProps
) => {
  return (
    <Popconfirm
      overlayInnerStyle={{ width: 320 }}
      placement="top"
      title={"Are you sure? This can not be reverted."}
      onConfirm={props.onOk}
      okText={"Ok"}
      cancelText={"Cancel"}
    >
      <Button
        loading={props.loading}
        disabled={props.disabled}
        danger={!props.primaryType}
        icon={props.icon || <DeleteOutlined />}
      >
        {props.caption}
      </Button>
    </Popconfirm>
  );
};


import { CenteredLayout } from "../../template/CenteredLayout";
import { Create, IntensityLevelsList } from "./IntensityLevelsList";


export function IntensityLevels() {
  return (
    <CenteredLayout title="Intensity Levels" extra={[<Create key="create" />]}>
      <IntensityLevelsList />
    </CenteredLayout>
  );
}

import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const defaultOptions = {
    queries: {
        cacheTime: Infinity,
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: 3,
        retryDelay: (attempt: number) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
    },
};

export const defaultQueryClient = new QueryClient({
    defaultOptions,
});
export const BeangineersQueryClientProvider = (props: { children: React.ReactNode }) => {
    return <QueryClientProvider client={defaultQueryClient}>{props.children}</QueryClientProvider>;
};
